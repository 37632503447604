

//export const BACKEND_SERVER_URL = "http://68.178.172.24:8000";
// export const BACKEND_SERVER_URL = "http://localhost:8006";
export const BACKEND_SERVER_URL = "https://admin.cartroofs.com";
// export const BACKEND_SERVER_URL = "https://nodeadmin.examinares.com";
//export const BACKEND_SERVER_URL = "https://node.insightupsc.com";
//export const BACKEND_SERVER_URL = "http://68.178.172.24:8005";
//export const BACKEND_SERVER_URL = process.env.NODE_ENV == "developement" ? "http://localhost:8000" : "http://68.178.172.24:8000" 


