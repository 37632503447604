import React, { useEffect, useId, useRef, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

import axios from 'axios';
import { toast } from 'react-toastify'
import { TbLayoutGrid } from "react-icons/tb";
// import CustomClearIndicator from './MultiSelectBatches';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { IoMdArrowRoundBack } from "react-icons/io";
import { FiPlay } from "react-icons/fi";

import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import Editor from 'ckeditor5-custom-build/build/ckeditor'

import Editor from '@ckeditor/ckeditor5-build-classic'
import { FaAngleDown, FaAngleUp, FaArrowDown } from "react-icons/fa6";
import { FaArrowUp } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";


// import { fetchCourseData, formateCourseData, generateUniqueID, getExamTypes, getFaculties, getTypes, validateCourseData } from './CourseHelper';
import '../Course/Course.css'

import { setDropDownStyle } from '../CommonHelper';
import { MdDeleteOutline } from 'react-icons/md';
import { Checkbox } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { generateUniqueID } from '../Course/CourseHelper';
const defaultPreviewImage = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbXndBtwczZa9FaZQ5KMoV4RmKdg1CecrCCw&s'


const CreatePronductPage = () => {
    const { productId } = useParams();

    const navigate = useNavigate();
    const [modalBox, setModalBox] = useState(false)
    const [modalBox2, setModalBox2] = useState(false)






    const [collapseVariantsIndex, setCollapseVariantsIndex] = useState(null);
    const fileInputRef = useRef(null);
    const imageFileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewFile, setPreviewFile] = useState(null);
    const [variantImageFile, setVariantImageFile] = useState(null);
    const [outerUploadingImageIndex, setOuterUploadingImageIndex] = useState(null);
    const [innerUploadingImageIndex, setInnerUploadingImageIndex] = useState(null);
    const [serverMedia, setServerMedia] = useState([]);
    const [mediaInputLink, setMediaInputLink] = useState('');
    const [bulkEditField, setBulkEditField] = useState('');
    const [bulkEditValue, setBulkEditValue] = useState(0);
    let intialProductDetails = {
        title: '',
        option1Name: 'Color',
        option2Name: 'Size',
        productCode: '',
        description: '',
        productMedia: [],
        status: 'active',
        type: '',
        vender: '',
        collections: '',
        productCategory: '',
        tags: '',
        price: 0,
        compareAtPrice: 0,
        isPysicalProduct: true,
        weight: 0,
        published: true,
    }
    const [productData, setProductData] = useState(intialProductDetails);


    let defaultImage = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWGgKnt4k7cn5fjg0hrhdPqSg8hC_UiWnC1w&s';

    let initialVariantArray = [
        { optionName: 'Color', optionValues: [{ value: "", imagePath: '', price: 0, available: 0, included: true }] },
        { optionName: 'Size', optionValues: [{ value: "", imagePath: '', price: 0, available: 0, included: true }] },
    ]

    const [showParentVariantsNames, setShowParentVariantsNames] = useState(productId ? true : false);
    const [showChildVariantsNames, setShowChildVariantsNames] = useState(productId ? true : false);
    const [parentVariantsNames, setParentVariantsNames] = useState(['']);
    const [childVariantsNames, setChildVariantsNames] = useState(['']);
    let childObj = { value: "", imagePath: '', price: 0, available: 0, included: true, childUniqueId: generateUniqueID() }

    let obj = { value: "", imagePath: '', price: 0, available: 0, parentUniqueId: generateUniqueID(), included: true, childValues: [childObj] };
    console.log(productData)
    const [variantsDetails, setVariantsDetails] = useState([obj]);

    // const setFinalVariants = () => {
    //     let tempVariants = variantsDetails?.map(obj => {
    //         return {
    //             ...obj, childValues: childVariantsDetails?.filter(obj => obj.value != 'Add another value' && obj.value != '')
    //         }
    //     })
    //     setVariantsDetails(tempVariants);
    // }

    // useEffect(() => {
    //     setFinalVariants();
    // }, [count])

    // useEffect(() => {
    //     // variantsDetails[0]?.optionValues?.filter(obj => obj.value != 'Add another value').forEach((variant1, index1) => {
    //     //     tempVariants.push({
    //     //         ...variant1, childValues: variantsDetails[1]?.optionValues?.filter(obj => obj.value != 'Add another value')
    //     //     })
    //     // })
    //     let tempVariants = variantsDetails?.filter(obj => obj.value != 'Add another value').map(obj => {
    //         return {
    //             ...obj, childValues: childVariantsDetails?.filter(obj => obj.value != 'Add another value' && obj.value != '')
    //         }
    //     })
    //     setVariantsToShow(tempVariants);
    // }, [variantsDetails, childVariantsDetails])
    console.log(variantsDetails)

    function rebuildVariantsDetails(modifiedData) {
        // console.log(modifiedData)
        let rebuiltVariantsDetails = [];

        modifiedData.forEach(item => {
            // Find the outer object (group by Color)
            let outerObj = rebuiltVariantsDetails.find(obj => obj.value === item.Option1_Value);

            if (!outerObj) {
                // If Option1_Value doesn't exist, create the outer object
                outerObj = {
                    included: true,
                    value: item.Option1_Value,  // outerObj.value is the Color
                    price: item.Price1,  // price can be the same for all Sizes under a color
                    available: item.Quantity1,  // Assuming available if quantity is > 0
                    imagePath: item.Image1,  // Image for the outer object (Color)
                    parentUniqueId: item.ProductId,  // Using ProductId as the parent unique ID
                    childValues: []  // Initialize childValues array for Sizes
                };
                rebuiltVariantsDetails.push(outerObj);
            }

            // Create the inner object for Size
            let innerObj = {
                included: true,
                value: item.Option2_Value,  // innerObj.value is the Size
                price: item.Price2,  // price for the Size (Price2)
                available: item.Quantity2,  // Assuming available if Quantity2 is > 0
                imagePath: item.Image2,  // Image for the Size

            };

            // Add the inner object to the childValues array of the outer object
            outerObj.childValues.push(innerObj);
        });

        return rebuiltVariantsDetails;
    }



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response1 = await axios.get(BACKEND_SERVER_URL + '/getSingleProduct/' + productId);
                let productData = response1.data.productData;
                let formatedData = {
                    option1Name: productData.Option1_Name,
                    option2Name: productData.Option2_Name,
                    title: productData.Title,
                    productCode: productData.ProductCode,
                    description: productData.Description,
                    productImage: productData.Product_Image,
                    status: productData.Status == 1 ? 'Active' : 'Inactive',
                    type: productData.Type,
                    vender: productData.Vendor,
                    isPysicalProduct: productData.IsPhysicalProduct,
                    collections: productData.Collections,
                    productCategory: productData.Category,
                    tags: productData.Tags,
                    compareAtPrice: productData.CompareAtPrice,
                    price: productData.Price,
                    weight: productData.Weight,
                }

                setProductData((prevData) => ({
                    ...prevData,  // Spread previous data
                    ...formatedData  // Spread the new data from the API (formatedData)
                }));
                const response2 = await axios.get(BACKEND_SERVER_URL + '/getProductMedia/' + productId);
                const response3 = await axios.get(BACKEND_SERVER_URL + '/getProductVariants/' + productId);
                let variants = response3.data.variants;
                variants = rebuildVariantsDetails(variants);
                // let length = variants[0].childValues.length;
                console.log(variants)
                // let childArray = [];
                // for (let i = 0; i < length; i++) {
                //     childArray.push(childObj);
                // }
                // childArray.push({ value: "Add another value", imagePath: '', price: 0, available: 0, included: true, childUniqueId: generateUniqueID() });
                variants = variants?.map((data, index) => {
                    data.childValues.push({ value: "Add another value", imagePath: '', price: 0, available: 0, included: true, childUniqueId: generateUniqueID() })
                    return {
                        ...data,
                        childValues: data.childValues
                    }
                })

                variants.push({ value: "Add another value", imagePath: '', price: 0, available: 0, included: true, parentUniqueId: generateUniqueID(), childValues: variants[0].childValues.map((data) => ({ ...data, imagePath: '', price: 0, available: 0 })) })
                console.log(variants)
                setVariantsDetails(variants);

                setProductData((prevData) => ({
                    ...prevData,
                    productMedia: response2.data.mediaData.map((element) => element.FilePath)
                }));
                setPreviewFile(response2?.data?.mediaData[0]?.FilePath);

            } catch (error) {
                console.log(error);

            }


        }
        if (productId) {
            fetchData();
        }


    }, [])
    useEffect(() => {
        setParentVariantsNames(variantsDetails?.filter(obj => obj.value !== "Add another value").map(obj => obj.value))
        setChildVariantsNames(variantsDetails[0]?.childValues?.filter(obj => obj.value !== "Add another value").map(obj => obj.value))
    }, [variantsDetails]);



    const handleUploadMediaFile = () => {
        if (selectedFile) {
            //upload image on server
            const formData = new FormData();
            formData.append('uploadedFile', selectedFile, selectedFile.name);

            axios.post(BACKEND_SERVER_URL + '/uploadFileOnServer', formData)
                .then((response) => {

                    if (productData.productMedia.length === 0) {
                        setPreviewFile(response.data.filePath)
                    }
                    setProductData((prevData) => ({
                        ...prevData,
                        productMedia: [...prevData.productMedia, response.data.filePath]
                    }));


                    setSelectedFile(null);
                    toast.success(response.data.message)

                })
                .catch(error => {
                    setSelectedFile(null);
                    toast.error(error.response.data.message);
                });
        }
    }

    const handleOptionNameChange = (index, newOptionName) => {
        let copy = [...variantsDetails];
        let filteredOption = copy[index];
        filteredOption.optionName = newOptionName;
        setVariantsDetails(copy);
    }
    const handleParentChange = (index, newOptionValue) => {
        let copy = [...variantsDetails];
        let filteredOption = copy[index];
        filteredOption.value = newOptionValue;
        let value = copy[copy.length - 1]?.value
        if (value !== "Add another value") {
            /*    filteredOption.childValues.push(childObj)
               console.log(filteredOption) */
            copy.push({ value: "Add another value", imagePath: '', price: 0, available: 0, included: true, childValues: filteredOption.childValues.map((data) => ({ ...data, imagePath: '', price: 0, available: 0 })) })
        }
        // setCount((preData) => preData + 1);
        setVariantsDetails(copy);

    }
    const handleChildChange = (index, newOptionValue) => {
        // let copy = [...childVariantsDetails];
        // let filteredOption = copy[index];
        // filteredOption.value = newOptionValue;
        // let value = copy[copy.length - 1]?.value;

        let copy2 = [...variantsDetails];
        copy2 = copy2.map(obj => ({
            ...obj,
            childValues: obj.childValues.map((child, indexChild) =>
                indexChild === index
                    ? { ...childObj, value: newOptionValue }
                    : child
            )
        }));

        let childUniqueId = generateUniqueID()
        let value = copy2[0].childValues[copy2[0].childValues.length - 1]?.value;
        if (value !== "Add another value") {
            // copy.push({ value: "Add another value", imagePath: '', childUniqueId: childUniqueId, price: 0, available: 0, included: true });
            copy2 = copy2.map(obj => ({
                ...obj,
                childValues: [...obj.childValues, { value: "Add another value", imagePath: '', childUniqueId: childUniqueId, price: 0, available: 0, included: true }]
            }));
        }
        // setChildVariantsDetails(copy);
        setVariantsDetails(copy2)
    }
    const handleParentFields = (outerIndex, value, fieldName) => {
        let copy = [...variantsDetails];
        let filteredValueObj = copy[outerIndex];
        filteredValueObj[fieldName] = value;
        //by default the image of parent variant will be assigned to the child.
        const updatedChildvalues = filteredValueObj['childValues']?.map(childValue => {
            return {
                ...childValue, [fieldName]: value
            }
        })
        filteredValueObj['childValues'] = updatedChildvalues;

        setVariantsDetails(copy);
    }
    const handleChildFields = (outerIndex, innerIndex, value, fieldName) => {
        let copy = [...variantsDetails];
        let outerObj = { ...copy[outerIndex] };
        let innerObj = { ...outerObj.childValues[innerIndex] };
        innerObj[fieldName] = value;
        outerObj.childValues = [...outerObj.childValues];
        outerObj.childValues[innerIndex] = innerObj;
        copy[outerIndex] = outerObj;
        setVariantsDetails(copy);
    };

    const handleClearField = (index) => {
        let copy = [...variantsDetails];
        let filteredOption = copy[index];
        // let optionValuesArray = filteredOption.optionValues;
        // let filteredValueObj = optionValuesArray[innerIndex];
        if (filteredOption.value == 'Add another value') {
            filteredOption.value = '';
        }
        setVariantsDetails(copy);
        // setCount((preData) => preData + 1);
    }
    const handleClearChildField = (index) => {
        let copy = [...variantsDetails];

        copy = copy.map(obj => ({
            ...obj,
            childValues: obj.childValues.map((child, indexChild) =>
                (child.value == 'Add another value' && indexChild == index)
                    ? { ...child, value: '' }
                    : child
            )
        }));

        // let filteredOption = copy[index];
        // if (filteredOption.value == 'Add another value') {
        //     filteredOption.value = '';
        // }
        setVariantsDetails(copy);
        // setCount((preData) => preData + 1);
    }
    const deleteParent = (index) => {
        let copy = [...variantsDetails];
        if (copy.length > 1 && copy[index].value != 'Add another value') {
            copy.splice(index, 1);
        }
        setVariantsDetails(copy);
        // setCount((preData) => preData + 1);
    }
    const deleteChild = (index) => {
        // let copy = [...variantsDetails];
        // let modifiedVariants = copy.map(parentObj => {
        //     let splicedObj = parentObj.childValues.splice(index, 1);
        //     parentObj.childValues.splice(index - 1, 0, splicedObj[0])
        //     return parentObj;
        // })
        // setVariantsDetails(modifiedVariants);




        // let copy = [...childVariantsDetails];
        // if (copy.length > 1 && copy[index].value != 'Add another value') {
        //     copy.splice(index, 1);
        // }
        // setChildVariantsDetails(copy);


        let copy = [...variantsDetails];
        copy = copy.map(obj => {
            return {
                ...obj,
                childValues: obj.childValues.length > 1 && obj.childValues[index].value != 'Add another value'
                    ?
                    obj.childValues.filter((child, indexChild) => indexChild !== index)
                    :
                    obj.childValues
            }
        });
        setVariantsDetails(copy)
    }
    const deleteOption = (outerIndex) => {
        let copy = [...variantsDetails];
        copy.splice(outerIndex, 1);
        setVariantsDetails(copy);
    }
    const handleUpOptionName = (index) => {
        let copy = [...variantsDetails];


        let splicedObj = copy.splice(index, 1);

        copy.splice(index - 1, 0, splicedObj[0]);

        setVariantsDetails(copy);
    }
    const handleDownOptionName = (index) => {
        let copy = [...variantsDetails];


        let splicedObj = copy.splice(index, 1);

        copy.splice(index + 1, 0, splicedObj[0]);

        setVariantsDetails(copy);
    }

    const handleUpParent = (index) => {
        let copy = [...variantsDetails];
        let splicedObj = copy.splice(index, 1);
        copy.splice(index - 1, 0, splicedObj[0]);
        setVariantsDetails(copy);
        // setCount((preData) => preData + 1);
    }
    const handleDownParent = (index) => {
        let copy = [...variantsDetails];
        let splicedObj = copy.splice(index, 1);
        copy.splice(index + 1, 0, splicedObj[0]);
        setVariantsDetails(copy);
        // setCount((preData) => preData + 1);
    }
    const handleUpChild = (index) => {
        let copy = [...variantsDetails];
        let modifiedVariants = copy.map(parentObj => {
            let splicedObj = parentObj.childValues.splice(index, 1);
            parentObj.childValues.splice(index - 1, 0, splicedObj[0])
            return parentObj;
        })
        setVariantsDetails(modifiedVariants);
    }

    const handleDownChild = (index) => {
        let copy = [...variantsDetails];
        let modifiedVariants = copy.map(parentObj => {
            let splicedObj = parentObj.childValues.splice(index, 1);
            parentObj.childValues.splice(index + 1, 0, splicedObj[0])
            return parentObj;
        })
        setVariantsDetails(modifiedVariants);
    }


    const handleCollapse = (outerIndex) => {
        if (outerIndex == collapseVariantsIndex) {
            setCollapseVariantsIndex(null);
        } else {
            setCollapseVariantsIndex(outerIndex);
        }

    }





    // // Function to generate combinations
    function generateModifiedData() {
        let modifiedData = [];
        // Loop through all combinations of option1 and option2 values
        variantsDetails?.filter(obj => /* obj.included && */ obj.value != 'Add another value' && obj.value != '')?.forEach((outerObj, outerIndex) => {
            outerObj?.childValues?.filter(obj => /* obj.included && */ obj.value != 'Add another value' && obj.value != '')?.forEach(innerObj => {
                modifiedData.push({
                    option1Name: 'Color',
                    option2Name: 'Size',
                    option1Value: outerObj.value,  // Access the value property
                    option2Value: innerObj.value,  // Access the value property
                    option1Price: outerObj.price,
                    option2Price: innerObj.price,
                    option1Available: outerObj.available,
                    option2Available: innerObj.available,
                    option1ImagePath: outerObj.imagePath,
                    option2ImagePath: innerObj.imagePath,
                    // ...productData
                    // ...modifiedData.length ? {} : productData
                });
            })
        });
        return modifiedData;
    }

    let variantData = generateModifiedData();



    useEffect(() => {
        if (variantImageFile) {
            //upload image on server
            const formData = new FormData();
            formData.append('uploadedFile', variantImageFile, variantImageFile.name);

            axios.post(BACKEND_SERVER_URL + '/uploadFileOnServer', formData)
                .then((response) => {
                    setVariantImageFile(null);
                    if (innerUploadingImageIndex === null) {
                        handleParentFields(outerUploadingImageIndex, response.data.filePath, 'imagePath');
                    } else {
                        handleChildFields(outerUploadingImageIndex, innerUploadingImageIndex, response.data.filePath, 'imagePath');
                    }

                    setOuterUploadingImageIndex(null);
                    setInnerUploadingImageIndex(null)
                    toast.success(response.data.message)

                })
                .catch(error => {
                    setOuterUploadingImageIndex(null);
                    setInnerUploadingImageIndex(null);
                    setVariantImageFile(null);
                    toast.error(error?.response?.data?.message);
                });
        }
    }, [variantImageFile])




    const uploadParentVariantImage = async (outerIndex) => {
        setOuterUploadingImageIndex(outerIndex)
        imageFileInputRef.current.click();
    }
    const uploadChildVariantImage = async (outerIndex, innerIndex) => {
        setOuterUploadingImageIndex(outerIndex);
        setInnerUploadingImageIndex(innerIndex);
        imageFileInputRef.current.click();
    }



    // handle save products
    const handleSubmitProduct = () => {
        if (productId) {
            axios.post(BACKEND_SERVER_URL + '/updateProduct/' + productId, { variantData, productData }).then((response) => {
                if (response.data.statusCode == 200) {
                    navigate("/product-management")
                    setProductData(intialProductDetails);
                    toast.success(response.data.message);
                }
            }).catch((error) => {
                toast.error(error.response.data.message)
                console.log(error)
            })
        } else {
            axios.post(BACKEND_SERVER_URL + '/saveProducts', { variantData, productData }).then((response) => {
                if (response.data.statusCode == 201) {
                    navigate("/product-management");
                    toast.success(response.data.message);
                }
            }).catch((error) => {
                toast.error(error.response.data.message)
                console.log(error)
            })
        }
    }
    useEffect(() => {
        if (modalBox) {
            axios.get(BACKEND_SERVER_URL + '/getServerMedia').then(response => {
                setServerMedia(response.data.mediaData)
            }).catch(error => {
                console.log(error);
                // toast.error(error.response.data.message);
            })
        } else {
            setMediaInputLink('');
            setSelectedFile(null);
        }

    }, [modalBox])

    const handleChecked = (checked, filePath) => {
        if (checked) {
            if (productData.productMedia.length === 0) {
                setPreviewFile(filePath)
            }
            setProductData((prevData) => ({
                ...prevData,
                productMedia: [...prevData.productMedia, filePath]
            }));
        } else {
            setProductData((prevData) => ({
                ...prevData,
                productMedia: prevData.productMedia.filter((element) => element != filePath)
            }));
        }
    }
    const handleSaveMediaLink = () => {
        if (mediaInputLink) {
            if (productData.productMedia.length === 0) {
                setPreviewFile(mediaInputLink)
            }
            setProductData((prevData) => ({
                ...prevData,
                productMedia: [...prevData.productMedia, mediaInputLink]
            }));
            setMediaInputLink('');
        }
    }

    const handleBulkEdit = () => {
        if (bulkEditField && bulkEditValue) {
            let copy = [...variantsDetails];
            let updatedVariants = copy/* ?.filter(obj => obj.included && obj.value != 'Add another value' && obj.value != '') */?.map((data, index) => {
                return {
                    ...data,
                    [bulkEditField]: bulkEditValue,
                    childValues: data.childValues.map(data2 => {
                        return {
                            ...data2,
                            [bulkEditField]: bulkEditValue,
                        }
                    })
                }
            })

            // let copy2 = [...childVariantsDetails];
            // let updatedChildVariants = copy2/* ?.filter(obj => obj.included && obj.value != 'Add another value' && obj.value != '') */?.map((data, index) => {
            //     return {
            //         ...data,
            //         [bulkEditField]: bulkEditValue
            //     }
            // })


            setVariantsDetails(updatedVariants);
            // setChildVariantsDetails(updatedChildVariants);
            setModalBox2(false);
            setBulkEditField('');
            setBulkEditValue(0);
        }
    }
    const handleDeleteMedia = (index) => {
        setProductData((prevData) => ({
            ...prevData,
            productMedia: prevData.productMedia.filter((element, indexMedia) => indexMedia != index)
        }));

    }

    return (
        <>
            {productId && <IoMdArrowRoundBack color='' size={30} className='text-primary  p-0 my-2 cursor-pointer' onClick={() => navigate("/product-management")} />}
            <div className=" d-flex justify-content-between">
                <div style={{ width: '66.35%' }} className="p-3 rounded-1 bg-white ">
                    <div className="my-4  border-gray-rounded  p-3">
                        <label htmlFor="title" className='form-label font-semibold'>Title</label>
                        <input value={productData.title} type="text" name="title" id="title" placeholder='Please enter title' className='border-gray-400 form-control' onChange={(e) => setProductData((prevData) => ({ ...prevData, title: e.target.value }))} />
                    </div>
                    <div className="my-4 border-gray-rounded p-3">
                        <label htmlFor="description" className='form-label font-semibold'>Description</label>
                        <CKEditor
                            id="description"
                            config={{
                                // extraPlugins: [uploadPlugin],


                            }}

                            editor={Editor}
                            // data="<p>Hello from CKEditor 5!</p>"
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                            }}
                            data={productData.description}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setProductData((prevData) => ({ ...prevData, description: data }));
                            }}
                            onBlur={(event, editor) => {
                            }}
                            onFocus={(event, editor) => {
                                //console.log( 'Focus.', editor );
                            }}

                        />
                    </div>
                    <div className="my-4 border-gray-rounded p-3">
                        <label htmlFor="title" className='form-label font-semibold'>Media</label>
                        <div className="border-dashed d-flex" style={{ minHeight: "200px" }}>
                            <div className="col-3 m-1" style={{ minHeight: "200px" }}>
                                {previewFile?.split('.')?.pop() == 'mp4' ?

                                    <video
                                        src={previewFile}
                                        className="border-2 border-secondary"
                                        controls
                                        style={{
                                            width: "100%",
                                            height: "230px",
                                            objectFit: "cover", // Ensures the video scales correctly
                                        }}
                                    /> :
                                    <img style={{
                                        width: "100%",
                                        height: "230px",
                                        cursor: "pointer",
                                        objectFit: "fill",
                                    }} className='' src={previewFile || defaultPreviewImage} alt="midea image" />
                                }
                            </div>
                            <div className="m-1 col-9 d-flex flex-wrap align-items-start" style={{ minHeight: "10rem" }}>
                                {
                                    productData?.productMedia.map((filePath, index) => {
                                        return (
                                            <div
                                                className="col-3 d-flex px-1"
                                                style={{ height: "5rem", position: "relative" }}
                                                onClick={() => setPreviewFile(filePath)}
                                            >
                                                {filePath?.split('.')?.pop() === 'mp4' ? (
                                                    <div style={{ position: "relative", width: "90%", height: "100%" }}>
                                                        <video
                                                            src={filePath}
                                                            className="border-2 border-secondary"
                                                            style={{
                                                                width: "90%",
                                                                height: "100%",
                                                                objectFit: "cover",
                                                            }}
                                                        />
                                                        {/* Play icon in the center of the video */}
                                                        <FiPlay
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                left: "50%",
                                                                transform: "translate(-50%, -50%)",
                                                                fontSize: "30px",
                                                                color: "white",
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <img
                                                        style={{
                                                            width: "90%",
                                                            height: "100%",
                                                            cursor: "pointer",
                                                            objectFit: "cover",
                                                        }}
                                                        className=""
                                                        src={filePath}
                                                        alt="media image"
                                                    />
                                                )}
                                                <TiDelete size={20} className='cursor-pointer' onClick={() => handleDeleteMedia(index)} />
                                            </div>
                                        );
                                    })

                                }
                                <div className="col-2 cursor-pointer border border-2 border-gray rounded d-flex justify-content-center align-items-center" style={{ height: "5rem" }}
                                    onClick={() => setModalBox(true)}
                                >
                                    <span className='fs-1 text-dark'>+</span>
                                </div>

                            </div>


                            {/* <div className="d-flex justify-content-center align-items-center">
                                <button onClick={() => setModalBox(true)} className='btn btn-sm border-gray-rounded p-1'>Upload new</button>
                                <span className='mx-2'>Select existing</span>
                            </div>
                            <div className="d-flex justify-content-center">
                                <p>Accepts images, video, or 3D models</p>
                            </div>
                            <div className="w-full bg-gray mt-2 d-flex ">
                                {productData?.productMedia?.map((filePath, index) => {
                                    const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'webm'];

                                    const fileExtension = filePath?.split('.')?.pop();
                                    return <div
                                        className="position-relative d-flex justify-content-center  m-2"
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "50%",
                                            overflow: "hidden", // To ensure the circular crop of the video
                                        }}
                                    >
                                        {videoExtensions.includes(fileExtension) ? <>
                                            <video
                                                src={filePath}
                                                className="border-2 border-secondary"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "cover", // Ensures the video scales correctly
                                                }}
                                            />
                                            <FiPlay
                                                size={30}
                                                color="white" // Play icon color
                                                className="position-absolute translate-middle"
                                                style={{
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)", // Ensures the icon is centered
                                                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent black background
                                                    padding: "10px",
                                                    borderRadius: "50%", // Circular background around the icon
                                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Subtle shadow for better visibility
                                                    cursor: "pointer", // Make the play button clickable
                                                }}
                                            />
                                        </> :
                                            <img src={filePath} alt="" style={{
                                                width: "100%",
                                                height: "100%",
                                                cursor: "pointer",
                                                objectFit: "cover", // Ensures the video scales correctly
                                            }} />

                                        }

                                    </div>
                                })}

                            </div> */}
                        </div>

                    </div>
                    <div className=" my-4 border-gray-rounded p-3">
                        <label htmlFor="option1" className='font-semibold'>Pricing</label>
                        <div className="d-flex justify-content-start align-items-center  ">
                            <div className="me-4">
                                <label htmlFor="price" className='form-label'>Price</label>
                                <input value={productData.price} type="number" name="price" id="price" placeholder='Please enter price' className='border-gray-400 form-control' onChange={(e) => setProductData((prevData) => ({ ...prevData, price: +e.target.value }))} />
                            </div>
                            <div className="">
                                <label htmlFor="comparePrice" className='form-label'>Compare at price</label>
                                <input value={productData.compareAtPrice} type="number" name="comparePrice" id="comparePrice" placeholder='Please enter compare at price ' className='border-gray-400 form-control' onChange={(e) => setProductData((prevData) => ({ ...prevData, compareAtPrice: +e.target.value }))} />
                            </div>
                        </div>

                    </div>

                    <div className=" my-4 border-gray-rounded p-3">
                        <label htmlFor="option1" className='font-semibold'>Shipping</label>
                        <div className="">
                            <div className="">
                                <Checkbox id='pysicalProduct' sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} checked={productData.isPysicalProduct} onChange={(e) => setProductData((prevData) => ({ ...prevData, isPysicalProduct: e.target.checked }))} />
                                <label htmlFor="pysicalProduct" className='form-label'>This is a physical product</label>
                            </div>
                            <div className="">
                                <label htmlFor="weight" className='form-label'>Weight</label>
                                <input value={productData.weight} type="number" name="weight" id="weight" placeholder='Please enter weight in kg ' className='0 form-control w-50' onChange={(e) => setProductData((prevData) => ({ ...prevData, weight: +e.target.value }))} />
                            </div>
                        </div>
                    </div>

                    {/*  {!productId ? */} <div className="my-4 border-gray-rounded p-3">
                        <label htmlFor="title" className='form-label text-lg font-semibold '>Variants</label>
                        {
                            showParentVariantsNames ?
                                <div className="cursor-pointer mb-3 border border-2 p-1 pb-2 border-light rounded" onClick={() => setShowParentVariantsNames(false)}>
                                    <h4>{productData.option1Name}</h4>
                                    <TbLayoutGrid size={18} color='gray' />
                                    {
                                        parentVariantsNames?.map((name, index) => {
                                            return <span className='bg-light p-1 text-dark m-1 font-semibold' key={index}>
                                                {name}
                                            </span>
                                        })
                                    }


                                </div>
                                :
                                <div className="mb-4" >
                                    <div className=" mb-3">
                                        <label htmlFor="option1" className='font-semibold'>Option Name</label>
                                        <div className="d-flex align-items-center">
                                            {/* <div style={{ minWidth: "3rem", maxWidth: "3rem" }} className="d-flex justify-content-between align-items-center ">
                                                <FaArrowUp className='cursor-pointer' size={16} onClick={() => handleUpOptionName(outerIndex)} />
                                                <FaArrowDown className='cursor-pointer' size={18} onClick={() => handleDownOptionName(outerIndex)} />
                                            </div> */}
                                            <input type="text" name="title" id="title" placeholder='Please Enter Option' value={productData.option1Name} className='border-gray-rounded form-control'
                                                onChange={(e) => setProductData((preData) => ({ ...preData, option1Name: e.target.value }))} />
                                            {/* <MdDeleteOutline color='gray' title='Delete Course' cursor='pointer' size={24} className='ms-2' onClick={() => deleteOption(outerIndex)}
                                            /> */}
                                        </div>
                                    </div>

                                    <div className="ps-4">
                                        <label htmlFor="option1" className='form-label'>Option Values</label>
                                        {variantsDetails.map((item, index) => {
                                            return <div className="d-flex align-items-center" key={index}>
                                                <div style={{ minWidth: "3rem", maxWidth: "3rem" }} className="d-flex justify-content-between align-items-center w-14">
                                                    <FaArrowUp className='cursor-pointer' size={14} onClick={() => handleUpParent(index)} />
                                                    <FaArrowDown className='cursor-pointer' size={16} onClick={() => handleDownParent(index)} />
                                                </div>
                                                <input type="text" name="title" id="title" placeholder='Please Enter values' value={item.value} className={` form-control m-1 ${item.value === 'Add another value' ? ' text-success' : ''}`}
                                                    onFocus={() => handleClearField(index)} onChange={(e) => handleParentChange(index, e.target.value)} />
                                                <MdDeleteOutline color='gray' title='Delete Course' cursor='pointer' size={24} className='ms-2' onClick={() => deleteParent(index)}
                                                />
                                            </div>
                                        })
                                        }

                                    </div>


                                </div>
                        }

                        {
                            showChildVariantsNames ?
                                <div className="cursor-pointer my-2 border border-2 p-1 pb-2 border-light rounded" onClick={() => setShowChildVariantsNames(false)}>
                                    <h4>{productData.option2Name}</h4>
                                    <TbLayoutGrid size={18} color='gray' />
                                    {
                                        childVariantsNames?.map((name, index) => {
                                            return <span className='bg-light p-1 text-dark m-1 font-semibold' key={index}>
                                                {name}
                                            </span>
                                        })
                                    }


                                </div>
                                :
                                <div className="mb-4" >
                                    <div className=" mb-3">
                                        <label htmlFor="option1" className='font-semibold'>Option Name</label>
                                        <div className="d-flex align-items-center">
                                            {/* <div style={{ minWidth: "3rem", maxWidth: "3rem" }} className="d-flex justify-content-between align-items-center ">
                                                <FaArrowUp className='cursor-pointer' size={16} onClick={() => handleUpOptionName(outerIndex)} />
                                                <FaArrowDown className='cursor-pointer' size={18} onClick={() => handleDownOptionName(outerIndex)} />
                                            </div> */}
                                            <input type="text" name="title" id="title" placeholder='Please Enter Option' value={productData.option2Name} className='border-gray-rounded form-control'
                                                onChange={(e) => setProductData((preData) => ({ ...preData, option2Name: e.target.value }))} />
                                            {/* <MdDeleteOutline color='gray' title='Delete Course' cursor='pointer' size={24} className='ms-2' onClick={() => deleteOption(outerIndex)}
                                            /> */}
                                        </div>
                                    </div>

                                    <div className="ps-4">
                                        <label htmlFor="option1" className='form-label'>Option Values</label>
                                        {variantsDetails[0]?.childValues?.map((item, index) => {
                                            return <div className="d-flex align-items-center">
                                                <div style={{ minWidth: "3rem", maxWidth: "3rem" }} className="d-flex justify-content-between align-items-center w-14">
                                                    <FaArrowUp className='cursor-pointer' size={14} onClick={() => handleUpChild(index)} />
                                                    <FaArrowDown className='cursor-pointer' size={16} onClick={() => handleDownChild(index)} />
                                                </div>
                                                <input type="text" name="title" id="title" placeholder='Please Enter values' value={item.value} className={` form-control m-1 ${item.value === 'Add another value' ? ' text-success' : ''}`}
                                                    onFocus={() => handleClearChildField(index)} onChange={(e) => handleChildChange(index, e.target.value)} />
                                                <MdDeleteOutline color='gray' title='Delete Course' cursor='pointer' size={24} className='ms-2' onClick={() => deleteChild(index)}
                                                />
                                            </div>
                                        })
                                        }

                                    </div>


                                </div>
                        }








                        {/* <div className="d-flex justify-content-between align-items-center">
                            <p className='ml-2 d-flex align-items-center text-blue-400 p-1 cursor-pointer' onClick={addMoreOption}><span className='text-2xl mr-1'>+</span>{variantsDetails.length ? 'Add another option' : 'Add option'}  </p>
                            <ButtonMUI variant="contained" color="success" onClick={() => setShowVariants(true)}>
                                        Done
                                    </ButtonMUI>
                        </div> */}
                        <hr className='mt-3' />
                        <input
                            type="file"
                            ref={imageFileInputRef}
                            style={{ display: 'none' }}
                            accept='image/*'
                            onChange={(e) => setVariantImageFile(e.target.files[0])}
                            className='form-control' />


                        <div className="">
                            <div className="d-flex justify-content-between px-2 text-md font-bold fs-5">
                                <label htmlFor="">Variants</label>
                                <label htmlFor="">Price</label>
                                <label htmlFor="">Available</label>
                            </div>
                            <hr className='' />
                            {variantsDetails?.filter(obj => obj.value !== 'Add another value' && obj.value != '').length &&
                                <div className="d-flex justify-content-end">
                                    <button className='btn btn-primary p-1 btn-xs' onClick={() => setModalBox2(true)}>Bulk Edit</button>
                                </div>}
                            {
                                variantsDetails?.filter(obj => obj.value != 'Add another value' && obj.value != '')?.map((variant, outerIndex) => {
                                    variant = {
                                        ...variant,  // Create a shallow copy of the variant object
                                        childValues: variant.childValues?.filter(obj => obj.value !== 'Add another value' && obj.value != '')  // Create a new array with the filtered child values
                                    };
                                    return (
                                        <div key={outerIndex} >
                                            <div className=" my-1 bg-gray-100 rounded-b  border-gray-300 rounded p-1" style={{ height: "5rem" }}  >
                                                <div className="w-100 d-flex justify-content-between align-items-center" style={{ height: "5rem" }}>
                                                    <div className="col-5 d-flex justify-content-start align-items-center">
                                                        <Checkbox style={{ pointerEvents: "none" }} sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} checked={variant.included} onChange={(e) => handleParentFields(outerIndex, e.target.checked, 'included')} />

                                                        <div className="mx-3 border-dotted border-primary p-1 cursor-pointer"
                                                            onClick={() => uploadParentVariantImage(outerIndex)}>
                                                            {
                                                                variant.imagePath ?
                                                                    <img className='object-fill  ' style={{ height: "3rem", width: "3rem" }} src={variant.imagePath} alt="" />
                                                                    :
                                                                    <img className='object-fill ' style={{ height: "3rem", width: "3rem" }} src={defaultImage} alt="" />
                                                            }
                                                        </div>
                                                        <div className=" ">
                                                            <p className="fs-5 p-0 m-0 font-semibold">{variant.value}</p>
                                                            <span className="d-flex align-items-center p-0 m-0 cursor-pointer" onClick={() => handleCollapse(outerIndex)}>
                                                                {variant.childValues?.length ? variant.childValues?.length : "No"} variants
                                                                {collapseVariantsIndex == outerIndex ? < FaAngleUp /> : <FaAngleDown />}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-between align-items-center">
                                                        <input style={{ width: "70%" }} type="number" name={`price${outerIndex}`} id="" className='form-control' value={variant.price} onChange={(e) => handleParentFields(outerIndex, +e.target.value, 'price')} />
                                                        <input style={{ width: "30%" }} type="number" name={`availabe${outerIndex}`} id="" className='form-control ms-1' value={variant.available} onChange={(e) => handleParentFields(outerIndex, +e.target.value, 'available')} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* children variants listing */}
                                            {
                                                (collapseVariantsIndex == outerIndex) && variant.childValues?.map((innerVariant, innerIndex) => {
                                                    return <div className=" my-1 bg-gray-50   border-gray-300 rounded p-1" style={{ height: "4rem" }}  >
                                                        <div className=" d-flex justify-content-end align-items-center" key={innerIndex}>
                                                            <div className="px-3 col-5 d-flex justify-content-start align-items-center">
                                                                <div className="d-flex justify-center align-items-center ">
                                                                    <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} className='pl-2' checked={innerVariant.included} onChange={(e) => handleChildFields(outerIndex, innerIndex, e.target.checked, 'included')} />
                                                                </div>

                                                                <div className="mx-3 border-dotted border-primary p-1 cursor-pointer "
                                                                    onClick={() => uploadChildVariantImage(outerIndex, innerIndex)}>
                                                                    {
                                                                        innerVariant.imagePath ?
                                                                            <img className='object-fill' style={{ height: "2rem", width: "2rem" }} src={innerVariant.imagePath} alt="" />
                                                                            :
                                                                            <img className='object-fill' style={{ height: "2rem", width: "2rem" }} src={defaultImage} alt="" />
                                                                    }
                                                                </div>
                                                                {/* <img className='object-fill h-6 ml-1 ' src={defaultImage} alt="" /> */}

                                                                <div className="d-flex d-flex-col justify-center ">
                                                                    <p className="font-medium text-2sm p-0 m-0">{innerVariant.value}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 d-flex justify-content-between align-items-center">
                                                                <input style={{ width: "70%", height: "2.4rem" }} type="number" name={`price${innerIndex}`} id="" className=' form-control' value={innerVariant.price} onChange={(e) => handleChildFields(outerIndex, innerIndex, +e.target.value, 'price')} />
                                                                <input style={{ width: "30%", height: "2.4rem" }} type="number" name={`available${innerIndex}`} id="" className='form-control  ms-1' value={innerVariant.available} onChange={(e) => handleChildFields(innerIndex, innerIndex, +e.target.value, 'available')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>

                                    )
                                })
                            }






                            {/* <div className="d-flex justify-content-between align-items-center h-9 mt-4 ">
                                        <div className="w-full grid grid-cols-5 gap-7 ">
                                            <div className="col-span-2 d-flex justify-evenly align-items-center">
                                                <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} className='pl-2'/>
                                                <img className='object-fill h-8' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWGgKnt4k7cn5fjg0hrhdPqSg8hC_UiWnC1w&s" alt="" />

                                                <div className="d-flex d-flex-col justify-center ">
                                                    <p className="font-medium text-2sm p-0 m-0">Black</p>                                                    
                                                </div>
                                            </div>
                                            <div className="col-span-3  grid grid-cols-3 gap-4">
                                                <input type="number" name="" id="" className='col-span-2 form-control !h-9 ' />
                                                <input type="number" name="" id="" className='form-control !h-9' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center h-9 mt-4 ">
                                        <div className="w-full grid grid-cols-5 gap-7 ">
                                            <div className="col-span-2 d-flex justify-evenly align-items-center">
                                                <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} className='pl-2'/>
                                                <img className='object-fill h-8' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWGgKnt4k7cn5fjg0hrhdPqSg8hC_UiWnC1w&s" alt="" />

                                                <div className="d-flex d-flex-col justify-center ">
                                                    <p className="font-medium text-2sm p-0 m-0">Black</p>                                                    
                                                </div>
                                            </div>
                                            <div className="col-span-3  grid grid-cols-3 gap-4">
                                                <input type="number" name="" id="" className='col-span-2 form-control !h-9 ' />
                                                <input type="number" name="" id="" className='form-control !h-9' />
                                            </div>
                                        </div>
                                    </div> */}

                        </div>




                    </div>
                    {/* //     :
                    //     <div className=" my-4 border-gray-rounded p-3">
                    //         <label htmlFor="option1" className='font-semibold'>Variants</label>
                    //         <div className="d-flex justify-content-start align-items-center ">
                    //             <div className="me-4">
                    //                 <label htmlFor="color" className='form-label'>Color</label>
                    //                 <input value={productData.color} type="text" name="color" id="color" placeholder='Please enter color  ' className='0 form-control ' onChange={(e) => setProductData((prevData) => ({ ...prevData, color: e.target.value }))} />
                    //             </div>
                    //             <div className="">
                    //                 <label htmlFor="size" className='form-label'>Size</label>
                    //                 <input value={productData.size} type="text" name="size" id="size" placeholder='Please enter size  ' className='0 form-control ' onChange={(e) => setProductData((prevData) => ({ ...prevData, size: e.target.value }))} />
                    //             </div>
                    //         </div>
                    //     </div>
                    // } */}
                </div>
                <div style={{ width: '33.31%' }} className="bg-white rounded p-3 ">
                    <div className="my-4 p-3  border-gray-rounded">
                        <label htmlFor="code" className='form-label font-semibold'>Product Code</label>
                        <input value={productData.productCode} type="text" name="code" id="code" placeholder='Please enter type' className='border-gray-400 form-control' onChange={(e) => setProductData((prevData) => ({ ...prevData, productCode: e.target.value }))} />
                    </div>
                    <div className="my-4 p-3  border-gray-rounded">
                        <label htmlFor="title" className='form-label font-semibold'>Status</label>
                        <Select
                            value={{ value: productData.status, label: productData.status }}
                            onChange={(selectedOption) => setProductData((prevData) => ({ ...prevData, status: selectedOption.value }))}
                            options={["Active", "Inactive"]?.map((item) => ({ value: item, label: item }))}
                            styles={setDropDownStyle()}
                        />
                    </div>

                    <div className=" my-4 border-gray-rounded p-3">
                        <label htmlFor="option1" className='font-semibold'>Product Organization</label>
                        <div className="my-3">
                            <div className="">
                                <label htmlFor="type" className='form-label'>Product Type</label>
                                <input value={productData.type} type="text" name="type" id="type" placeholder='Please enter type' className='border-gray-400 form-control' onChange={(e) => setProductData((prevData) => ({ ...prevData, type: e.target.value }))} />
                            </div>
                            {/* <div className="my-3">
                                <label htmlFor="vender" className='form-label'>Vender</label>
                                <input readOnly value={productData.vender} type="text" name="vender" id="vender" placeholder='Please enter vender name ' className='border-gray-400 form-control' onChange={(e) => setProductData((prevData) => ({ ...prevData, vender: e.target.value }))} />
                            </div> */}
                            <div className="my-3">
                                <label htmlFor="category" className='form-label'>Product Category</label>
                                <input value={productData.productCategory} type="text" name="category" id="category" placeholder='Please enter categories' className='border-gray-400 form-control' onChange={(e) => setProductData((prevData) => ({ ...prevData, productCategory: e.target.value }))} />
                            </div>
                            <div className="my-3">
                                <label htmlFor="collectins" className='form-label'>Collections</label>
                                <input value={productData.collections} type="text" name="collectins" id="collectins" placeholder='Please enter collections ' className='border-gray-400 form-control' onChange={(e) => setProductData((prevData) => ({ ...prevData, collections: e.target.value }))} />
                            </div>
                            <div className="my-3">
                                <label htmlFor="tags" className='form-label'>Tags</label>
                                <input value={productData.tags} type="text" name="tags" id="tags" placeholder='Please enter tags ' className='border-gray-400 form-control' onChange={(e) => setProductData((prevData) => ({ ...prevData, tags: e.target.value }))} />
                            </div>
                        </div>


                    </div>

                </div>

            </div>
            <div className='d-flex justify-content-end align-items-center px-3 py-3 bg-white rounded mb-3 my-3'>
                <Button variant="danger light" className='btn-sm me-3' onClick={() => navigate("/product-management")}>Cancel</Button>
                <Button variant="primary " className='btn-sm ' onClick={handleSubmitProduct}>{productId ? 'Update Product' : 'Create Product'}</Button>
            </div>
            <Modal onHide={setModalBox} show={modalBox} size="xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Upload media</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3 d-flex justify-content-center" >
                            <div className="" style={{ width: "75%" }} >
                                <div>
                                    <label htmlFor="mediaLink" className='form-label'>Enter file link</label>
                                    <div className="d-flex justify-content-between">
                                        <input style={{ maxWidth: "82%" }} value={mediaInputLink} type="text" name="mediaLink" id="mediaLink" placeholder='Please enter media link ' className='border-gray-400 form-control' onChange={(e) => setMediaInputLink(e.target.value)} />
                                        <button style={{ minWidth: "15%" }} type="button" className="btn btn-primary btn-xs" onClick={handleSaveMediaLink}>Save </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='text-center text-primary fs-4 font-semibold pb-0 mb-0'>OR</p>
                        <div className="mb-3  d-flex justify-content-center" >
                            <div className="" style={{ width: "75%" }} >
                                <div>
                                    <label htmlFor="basic-url" className="form-label d-block">Upload file</label>
                                    <div className="d-flex justify-content-between">
                                        <input
                                            type="file"
                                            // ref={fileInputRef}
                                            style={{ maxWidth: "82%" }}
                                            accept='image/*,video/*'
                                            onChange={(e) => setSelectedFile(e.target.files[0])}
                                            className='form-control' />
                                        <Button style={{ minWidth: "15%" }} type="button" className="btn btn-primary btn-xs" onClick={handleUploadMediaFile}>Upload file</Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-3 mt-5 d-block">
                            <label htmlFor="basic-url" className="form-label d-block text-primary fs-4 font-semibold">Existing midea at server</label>
                            <div className="d-flex justify-content-start flex-wrap">
                                {serverMedia.map((mediaObj) => {
                                    return <div className=" " >
                                        <div className="d-flex border border-2 border-gray rounded pe-4 bg- mx-1" style={{ backgroundColor: "#d3d3d3" }} >
                                            <Checkbox className='align-self-start p-0' sx={{ '& .MuiSvgIcon-root': { fontSize: 26 } }} checked={productData.productMedia.includes(mediaObj.FilePath)} onChange={(e) => handleChecked(e.target.checked, mediaObj.FilePath)} />
                                            <div className="">
                                                <img style={{ width: "10rem", height: "12rem" }} src={mediaObj.FilePath} alt="" />
                                            </div>

                                        </div>
                                        {/* <p >{mediaObj.FilePath}</p> */}
                                    </div>
                                })}



                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn btn-danger btn-md" onClick={() => setModalBox(false)}>Close</button> */}
                        <button type="button" className="btn btn-success btn-md" onClick={() => setModalBox(false)}>Ok</button>
                    </div>
                </div>
            </Modal >
            <Modal onHide={setModalBox2} show={modalBox2} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Bulk Edit</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox2(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label text-primary">Select Field<span className="required">*</span></label>
                            <br />
                            <Select
                                defaultValue={{ value: 'Please Select Field', label: 'Please Select Field' }}  // Default value displayed initially
                                value={bulkEditField ? { value: bulkEditField, label: bulkEditField } : null}
                                onChange={(selectedOption) => setBulkEditField(selectedOption?.value == 'quantity' ? 'available' : selectedOption?.value)}
                                options={['quantity', 'price'].map((status) => ({ value: status, label: status }))}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        minWidth: '13rem',
                                        backgroundColor: 'white',
                                        borderColor: '#17a2b8',
                                    }),
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="bulkvalue" className='form-label'>Enter Value</label>
                            <input value={bulkEditValue} type="text" name="bulkvalue" id="bulkvalue" placeholder='Please enter media link ' className='border-gray-400 form-control' onChange={(e) => setBulkEditValue(+e.target.value)} />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-md" onClick={() => setModalBox2(false)}>Close</button>
                        <button type="button" className="btn btn-success btn-md" onClick={handleBulkEdit}>Update</button>
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default CreatePronductPage;






