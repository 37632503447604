import React from 'react';

const ReportManagement = () => {
  return (
      <div>
          <h2>
              Comming Soon....
          </h2>
      </div>
  );
}

export default ReportManagement;
