import React, { Fragment, useEffect, useRef, useState } from "react";

/// Data
import productData from "../AppsMenu/Shop/productData";
import PageTitle from "../../layouts/PageTitle";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { BACKEND_SERVER_URL } from "../../../http/http-config";

import axios from "axios";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import swal from 'sweetalert';
import { Badge, Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, showTableResponse } from "../common-helpers/commonServices";
import SearchBox from "../Common-Components/SearchBox";
import ProdutsDetailsTabsAtTop from "../Common-Components/ProdutsDetailsTabsAtTop";
import '../common-css/Table.css'
import { CSVLink } from 'react-csv';
import allProduct from "../AppsMenu/Shop/productData/allProducts";
// import CustomerModal from "./CustomerModal";

const StaffManagement = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [checked, setChecked] = useState(courses);
    const [unchecked, setUnChecked] = useState(true);
    const [loadCourses, setCourseLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState(courses);
    const [allProducts, setAllProducts] = useState(courses);
    const [records, setRecords] = useState(courses);
    const [fileUploading, setFileUploading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [modalBox, setModalBox] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [count, setCount] = useState(0);

    // search functionality
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const filteredCourses = courses?.filter(
            item => item?.courseTitle?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setSelectedCourses(filteredCourses)
    }, [searchTerm])


    const pageSize = 10;
    const npage = Math.ceil(count / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1);



    useEffect(() => {
        async function fetchData() {
            try {
                let response1 = await axios.get(BACKEND_SERVER_URL + '/getStaffCount');
                let count = response1.data.count;
                setCount(count);
            } catch (error) {
                //toast.error(error.response.data.message)
                console.log(error)
            }
        }
        fetchData();
    }, [loadCourses])

    useEffect(() => {
        setIsLoading(true)
        async function fetchData() {
            try {
                let response = await axios.get(BACKEND_SERVER_URL + `/getStaffWithPagination/${currentPage}/${pageSize}`);
                console.log(response.data.staffs)
                setRecords(response.data.staffs)
                setIsLoading(false)

                // let response2 = await axios.get(BACKEND_SERVER_URL + `/getAllProducts`);
                // setAllProducts(response2.data.products);


            } catch (error) {
                setIsLoading(false)
                //toast.error(error.response.data.message)
                console.log(error)
            }
        }
        fetchData();
    }, [currentPage, loadCourses])








    return (
        <Fragment>
            <div className="col-xl-12">
                <div className=" page-title p-2 mb-2">
                    <div className=' col-8 d-flex justify-content-start'>
                        <div className="col-3">
                            <button type="button" className="btn btn-dark btn-sm "
                                onClick={() => navigate('/create-staff') /* childRef.current.openModal() */}
                            >
                                + Add User
                            </button>
                            {/* <button type="button" className="btn btn-success  btn-xs " onClick={() => childRef.current?.openModal()}> + Add Course</button> */}
                        </div>
                        <div className="col-3">
                            {/* <CSVLink
                                data={allProducts}
                                headers={headers}
                                filename={"table-data.csv"}
                                className="btn btn-primary btn-sm"
                                target="_blank"
                            >
                                Export to CSV
                            </CSVLink> */}

                            {/* <button type="button" className="btn btn-success  btn-xs " onClick={() => childRef.current?.openModal()}> + Add Course</button> */}
                        </div>

                    </div>
                    <div className=" col-4 input-group search-area mb-md-0 mb-3 w-25 " >
                        {/* <input type="text" className="form-control" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search here..." />
                        <span className="input-group-text"><Link to={"#"}>
                            <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                            </svg>
                        </Link>
                        </span> */}
                        <SearchBox /* setRecords={setRecords} dataArray={records} searchingProperty={'Title'} */ />
                    </div>
                </div>
                {/* <ProdutsDetailsTabsAtTop moduleType='course' setChecked={setSelectedCourses} totalItems={courses} totalActiveItems={totalActiveCourses} totalInactiveItems={totalInactiveCourses} totalArchivedItems={totalArchivedCourses} /> */}
            </div>
            <div className="dataTables_wrapper no-footer">
                <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div>
                        <Button variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/deleteUsers', 'Users', setCourseLoading, loadCourses)}>Delete</Button>
                        <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateUsers', 'Users', setCourseLoading, loadCourses)}>Active</Button>
                        <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inactivateUsers', 'Users', setCourseLoading, loadCourses)}>Inactive</Button>
                    </div>
                    <div className='dataTables_info text-dark'>
                        Records: {count}
                    </div>
                    {count > 1 && <div className="">
                        <div className=""
                            id="example-student_wrapper">
                            <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                        </div>
                    </div>
                    }
                </div>
            </div>

            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                <div className="table-responsive full-data" style={{ paddingBottom: "1px"/* , transform: 'rotateX(180deg)', transform: 'rotateX(180deg)'  */ }}>
                    <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                        <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" /* style={{ transform: 'rotateX(180deg)' }}  */ id="example-student">
                            <thead style={{ backgroundColor: "#cccccc" }} className="custom-table-header">
                                <tr className='text-center  border border-left '>
                                    <th className=' custom-table-header  p-2 '>SN</th>
                                    <th className=' custom-table-header  p-2'>
                                        <input type="checkbox" className="form-check-input" id="checkAll"

                                            onClick={() => handleCheckedAll(unchecked, records, setRecords, setUnChecked)}
                                        />
                                    </th>
                                    <th className='p-2 text-start  custom-table-header '>STATUS</th>
                                    <th className=' text-start custom-table-header  p-2' >NAME</th>
                                    <th className='custom-table-header  p-2' >USER TYPE</th>
                                    <th className=' custom-table-header  p-2' >CONTACT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {records?.length && !isLoading > 0 ? records?.map((item, ind) => (
                                    <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                        <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0"> {item.ID}</h6></td>
                                        <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                            <div className="checkbox me-0 align-self-center">
                                                <div className="custom-control custom-checkbox ">
                                                    <input type="checkbox"
                                                        className="form-check-input"
                                                        id={`stud-${item.ID}`}
                                                        checked={item.inputchecked}
                                                        onChange={() => handleChecked(item.ID, setRecords, records)}
                                                    />
                                                    <label className="custom-control-label" htmlFor={`stud-${item.ID}`}></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                            {item.status === 1 && <h6 className="text-success mb-0 ">Active </h6>}
                                            {item.status === 0 && <h6 className="text-danger mb-0">Inactive </h6>}
                                        </td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="flex justify-between">
                                                <h6 className='mb-0 flex justify-between' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                    <span className="cursor-pointer"  > {item.name}{' '}</span>
                                                    <CiEdit cursor='pointer' title='Edit User' size={18} className='ms-3' onClick={() => navigate('/update-staff/' + item.ID)} />
                                                    <MdDeleteOutline color='red' title='Delete User' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.ID, 'User', '/deleteStaff/', setCourseLoading, loadCourses) }}
                                                    />
                                                </h6>
                                            </div>
                                        </td>
                                        <td className='text-center p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "90px" }}>
                                            <h6 className={`mb-0 text-capitalize text-${item.type === 'manager' ? 'success' : (item.type === 'operator' ? 'warning' : 'muted')}`}>
                                                {item.type}
                                            </h6>

                                        </td>
                                        <td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "100px" }}>
                                            <h6 className='mb-0 '>{item.contact}{' '}</h6>
                                        </td>



                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                {isLoading ? <Spinner animation="grow" variant="info" />
                                                    :
                                                    <h3>No Data Found</h3>
                                                }
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* <CustomerModal ref={childRef} loadCourses={loadCourses} setCourseLoading={setCourseLoading} /> */}
        </Fragment >
    );
};

export default StaffManagement;




