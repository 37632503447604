import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CourseSampleFile from '../../../download_files/course_sample_file.docx'
// import CustomClearIndicator from './MultiSelectBatches';
import { BACKEND_SERVER_URL } from '../../../http/http-config';


const ShortStockModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);    //new course
    const [variantId, setVariantId] = useState(null);
    const [quantity, setQuantity] = useState(0);

    useImperativeHandle(ref, () => ({
        openModal(variantId) {
            if (variantId) {
                setVariantId(variantId);
                axios.get(BACKEND_SERVER_URL + '/getVariantQuantity/' + variantId).then((response) => {
                    setQuantity(response.data.data[0].Quantity2);
                })
            }
            setModalBox(true);


        }
    }));

    const handleSubmitQuantity = () => {
        axios.put(BACKEND_SERVER_URL + `/updateVariantsQuantity/${variantId}`, { quantity }).then((response) => {
            if (response.data.statusCode == 200) {
                setQuantity(0)
                toast.success(response.data.message)
                props.setCourseLoading(!props.loadCourses)
                setModalBox(false);
                setVariantId(null)
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }


    return (
        <>
            <Modal className="" show={modalBox} onHide={setModalBox} backdrop="static"
                keyboard={false} centered >
                <Modal.Header>
                    <Modal.Title>Variant Quantity</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between align-items-center flex-wrap px-2 ">
                        <label htmlFor="quantity" className='form-label'>Quantity<span className="required">*</span></label>
                        <input value={quantity} type="number" name="quantity" id="quantity" placeholder='Please enter variant quantity' className='border-gray-400 form-control' onChange={(e) => setQuantity(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitQuantity}>Update Quantity </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
})
export default ShortStockModal;