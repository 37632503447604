import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { IMAGES } from '../Dashboard/Content';
import Select from "react-select";
import { toast } from 'react-toastify';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Button } from 'react-bootstrap';
import { IoMdArrowRoundBack } from 'react-icons/io';
// import CustomClearIndicator from './MultiSelectTabs';

const AddNewTeacher = () => {
    const initialData = {
        name: '',
        contact: '',
        email: '',
        password: '',
        photo: IMAGES.noimage,
        address: '',
        userId: 0
    }
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [userData, setUserData] = useState(initialData);
    console.log(file)

    useEffect(() => {
        const { name, email, contact, address, pic, password, ID } = JSON.parse(localStorage.getItem('userDetails'));
        setUserData((preData) => ({ ...preData, name, email, contact, address, photo: pic, password, userId: ID }))
    }, [])



    useEffect(() => {
        let contact = userData?.contact;
        if (contact?.toString().length >= 10) {
            console.log('enter')
            let arr = contact.toString().split('')
            if (arr[0] == 0) {
                arr.shift();
                setUserData({ ...userData, contact: arr.join('') })
            }

        }
    }, [userData.contact])

    useEffect(() => {
        if (file) {
            //upload image on server
            const formData = new FormData();
            formData.append('uploadedFile', file, file.name);
            axios.post(BACKEND_SERVER_URL + '/uploadFileOnServer', formData)
                .then((response) => {
                    console.log(response);
                    setUserData({ ...userData, photo: response.data.filePath })
                    setFile(null);
                    toast.success(response?.data?.message);
                })
                .catch(error => {
                    setFile(null);
                    toast.error(error?.response?.data?.message);
                });
        }
    }, [file])
    const handleSubmitStaff = () => {
        if (!userData.name) {
            toast.error("Please Enter Name");
            return
        } else if (!userData.contact) {
            toast.error("Please Enter Contact Number");
            return
        } else if (!userData.email) {
            toast.error("Please Enter Email");
            return
        } else if (!userData.password) {
            toast.error("Please password first");
            return
        } else if (!userData.address) {
            toast.error("Please password first");
            return
        } else if (!userData.userId) {
            toast.error("User ID is missing");
            return
        }
        const endpoint = `${BACKEND_SERVER_URL}/updateMyAccountData/${userData.userId}`;
        axios.post(endpoint, userData).then((response) => {
            if (response.data.statusCode == 200) {
                toast.success(response.data.message);
                const userDetails = JSON.parse(localStorage.getItem('userDetails'));
                const updatedData = { ...userDetails, name: userData.name, email: userData.email, contact: userData.contact, address: userData.address, pic: userData.photo, password: userData.password, ID: userData.userId }
                localStorage.setItem('userDetails', JSON.stringify(updatedData))
                navigate("/my-account");
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    {userData.userId && <IoMdArrowRoundBack color='' size={30} className='text-primary  p-0 my-2 cursor-pointer' onClick={() => navigate("/my-account")} />}
                    <div className="card">
                        <div className="card-header">
                            <label htmlFor="option1" className='font-semibold fs-5'>Staff Details</label>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-6 col-sm-6">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label ">Name<span className="required">*</span></label>
                                        <input value={userData.name} onChange={(e) => setUserData({ ...userData, name: e.target.value })} type="text" className="form-control" id="name" placeholder="James" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label ">Email<span className="required">*</span></label>
                                        <input value={userData.email} onChange={(e) => setUserData({ ...userData, email: e.target.value })} type="email" className="form-control" id="email" placeholder="hello@example.com" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="address" className="form-label ">Address<span className="required">*</span></label>
                                        <textarea value={userData.address} onChange={(e) => setUserData({ ...userData, address: e.target.value })} className="form-control" id="address" rows="5" />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-sm-6">
                                    <div className="mb-3">
                                        <label htmlFor="contact" className="form-label ">Phone Number<span className="required">*</span></label>
                                        <input value={userData.contact} onChange={(e) => setUserData({ ...userData, contact: e.target.value })} type="number" name='contact' className="form-control" id="contact" placeholder="8923842332" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="passeword" className="form-label ">Password<span className="required">*</span></label>
                                        <input value={userData.password} onChange={(e) => setUserData({ ...userData, password: e.target.value })} type="text" className="form-control" id="passeword" placeholder="Tsdfsd234@#" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label ">Photo<span className="required">*</span></label>
                                        <div className="avatar-upload">
                                            <div className="avatar-preview">
                                                <div id="imagePreview"
                                                    style={{
                                                        backgroundImage: "url(" + userData.photo + ")"
                                                    }}
                                                > </div>
                                            </div>
                                            <div className="change-btn mt-1">
                                                <input type='file' onChange={(e) => setFile(e.target.files[0])} className="form-control d-none" id="imageUpload" accept=".png, .jpg, .jpeg" />
                                                <label htmlFor="imageUpload" className="dlab-upload mb-0 btn btn-primary btn-sm"  >Choose File</label>
                                                <Link to={"#"} className="btn btn-danger light remove-img ms-2 btn-sm" >Remove</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end align-items-center px-3 py-3 bg-white rounded mb-3 my-3'>
                                    <Button variant="danger light" className='btn-sm me-3' onClick={() => navigate('/staff-management')}>Cancel</Button>
                                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitStaff} >Update</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AddNewTeacher;











































