import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import Select from 'react-select';
import '../Course/Course.css';
import { Checkbox, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';

const CreateOrder = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    //new code    
    let intialOrderDetails = {
        productId: 0,
        title: 'Select product name',
        quantity: 0,
        price: 0,
        customerName: '',
        customerContact: '',
        customerEmail: '',
        includedVariants: [],
        totalVariants: 0,
        lead: 'WhatsApp',
        variantIds: '',
        variantString: ''

    }
    const [orderData, setOrderData] = useState(intialOrderDetails);
    const [products, setProducts] = useState([]);
    const [productVariant, setProductVariant] = useState([]);
    console.log(orderData)
    // handle save products
    const handleSubmitOrder = () => {
        if (!orderData.customerName) {
            toast.error("Please Enter Customer Name");
            return
        } else if (!orderData.customerContact) {
            toast.error("Please Enter Customer Contact Number");
            return
        } else if (!orderData.customerEmail) {
            toast.error("Please Enter Customer Email");
            return
        } else if (!orderData.lead) {
            toast.error("Please select order lead");
            return
        } else if (!orderData.productId) {
            toast.error("Please select any product first");
            return
        }
        else if (!orderData.totalVariants) {
            toast.error("Please select any variant first");
            return
        }
        const endpoint = orderId
            ? `${BACKEND_SERVER_URL}/updateOrder/${orderId}`
            : `${BACKEND_SERVER_URL}/saveOrder`;
        axios.post(endpoint, orderData).then((response) => {
            if (response.data.statusCode == 200) {
                navigate("/order-management");
                toast.success(response.data.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response1 = await axios.get(BACKEND_SERVER_URL + '/getOrderData/' + orderId);
                let orderData = response1.data.orderData;
                let formatedData = {
                    productId: orderData.ProductId,
                    title: orderData.ProductName,
                    variantString: orderData.Variants,
                    variantIds: orderData.VariantIDs,
                    price: orderData.TotalPrice,
                    customerId: orderData.CustomerId,
                    customerName: orderData.name,
                    customerContact: orderData.contact_number,
                    customerEmail: orderData.email,
                    lead: orderData.LeadFrom
                }
                setOrderData(formatedData);
                return
            } catch (error) {
                console.log(error);
            }
        }
        if (orderId) fetchData();
    }, [orderId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response1 = await axios.get(BACKEND_SERVER_URL + '/getProductsForDropdowns');
                setProducts(response1.data.products)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response1 = await axios.get(BACKEND_SERVER_URL + '/getProductsVariantForDropdowns/' + orderData.productId);
                let updatedVariants = response1.data.variants.map(obj => {
                    return {
                        ...obj, included: orderData?.variantIds?.includes(obj.ID) ? true : false
                    }
                })
                setProductVariant(updatedVariants)
            } catch (error) {
                console.log(error);
            }
        }
        if (orderData.productId) fetchData();
    }, [orderData.productId])

    useEffect(() => {
        let result = productVariant?.filter((item, index) => item.included);
        console.log(result)

        let totalPrice = 0;
        if (result.length > 0) {
            //calculate price
            result.forEach((item) => {
                totalPrice += (item.sellingQuantity * item.Price2);
            })
            setOrderData((prevData) => ({
                ...prevData, variantIds: result?.map(obj => obj.ID).join(',') || '', includedVariants: result, totalVariants: result.length, price: totalPrice, variantString: result?.map(obj => obj.Option1_Value + '-' + obj.Option2_Value + '-' + '(' + obj.sellingQuantity + ')').join(' , ') || '',
            }));
        } else {
            setOrderData((prevData) => ({
                ...prevData, variantIds: result?.map(obj => obj.ID).join(',') || '', variantString: result?.map(obj => obj.Option1_Value + '-' + obj.Option2_Value + '-' + '(' + obj.sellingQuantity + ')').join(',') || '',
            }));
        }
    }, [productVariant])

    const handleVariantChange = (id, value, fieldName) => {
        console.log(id, value, fieldName)
        let result;
        if (fieldName == 'included') {
            result = productVariant.map((item, index) => {
                return item.ID == id ? { ...item, included: !item.included } : item;
            })
        } else if (fieldName == 'price') {
            result = productVariant.map((item, index) => {
                return item.ID == id ? { ...item, Price2: value } : item;
            })
        } else {
            result = productVariant.map((item, index) => {
                if (item.ID == id) {
                    if (item.Quantity2 < value) {
                        toast.warning(`We have only ${item.Quantity2} variants available in stock`);
                        return item;
                    }
                    return { ...item, sellingQuantity: value };
                } else {
                    return item;
                }
            })

        }
        setProductVariant(result);

    }
    useEffect(() => {
        if (countDigits(orderData.customerContact) == 10) {
            axios.post(BACKEND_SERVER_URL + '/checkExistingCustomer', { contact: orderData.customerContact }).then((response) => {
                console.log(response.data.customerData)
                if (response.data.customerData.ID) {
                    let { name, contact, email, ID, address } = response.data.customerData;
                    setOrderData((prevData) => ({ ...prevData, customerName: name, customerEmail: email, customerAddress: address }))
                }
            }).catch((error) => {
                toast.error(error.response.data.message)
                console.log(error)
            })


        }

    }, [orderData.customerContact])

    function countDigits(number) {
        if (number === 0) return 1;
        return Math.floor(Math.log10(Math.abs(number))) + 1;
    }
    return (
        <>
            {orderId && <IoMdArrowRoundBack color='' size={30} className='text-primary  p-0 my-2 cursor-pointer' onClick={() => navigate("/order-management")} />}
            <div className=" d-flex justify-content-between">
                <div style={{ width: '100%' }} className="p-3 rounded-1 bg-white ">
                    <div className="my-4   border-gray-rounded  p-3">
                        <div className="" style={{ width: '60%' }}>
                            <label htmlFor="title" className='form-label font-semibold'>Product Name</label>
                            <Select
                                value={{
                                    value: orderData.title,
                                    label: orderData.title
                                }}

                                onChange={(selectedOption) => setOrderData((prevData) => ({
                                    ...prevData, title: selectedOption.value.Title, productId: selectedOption.value.ID, variantIds: '', variantString: '', quantity: 0, price: 0, totalVariants: 0, includedVariants: [], totalVariants: 0,
                                }))}
                                options={products?.map((item) => ({ value: item, label: item.Title }))}
                            // getOptionLabel={(e) => (
                            //     <div style={{ display: 'flex', alignItems: 'center' }}>
                            //         {orderData.productId != e.value.ID && <img src={e.value.Product_Image} alt={e.label} style={{ width: 20, height: 20, marginRight: 10 }} />}
                            //         {e.label}
                            //     </div>
                            // )}

                            />
                        </div>

                        {productVariant.length > 0 && <label htmlFor="title" className='form-label font-semibold mt-4'>Product Variants</label>}
                        <div className="d-flex justify-content-between align-items-center flex-wrap px-3">
                            {
                                productVariant.map((item, index) => {
                                    return <div style={{ width: "45%", height: "4rem" }} className=" my-1 bg-gray-50  d-flex justify-content-between align-items-center border-gray-300 rounded p-1">
                                        <div style={{ width: "100%" }} className=" d-flex justify-content-between align-items-center" key={item.ID}>
                                            <div className="col-5 d-flex justify-content-start align-items-center">
                                                <div className="d-flex justify-center align-items-center ">
                                                    <Checkbox disabled={Number(item.Quantity2) < 1 ? true : false} sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} className='' checked={item.included} onChange={(e) => handleVariantChange(item.ID, e.target.checked, 'included')} />
                                                </div>
                                                <div className="d-flex d-flex-col justify-center ">
                                                    <p className="font-medium text-2sm p-0 m-0">{item.Option1_Value + '-' + item.Option2_Value}</p>
                                                </div>
                                            </div>
                                            <div className="col-7 d-flex justify-content-between align-items-center">
                                                <TextField
                                                    label="price"
                                                    value={item.Price2}
                                                    size="small"
                                                    type="number" name={`price`} id="" className='form-control'
                                                    style={{ width: "30%" }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            zIndex: 0, // Decrease the z-index of the label
                                                        },
                                                    }}
                                                    onChange={(e) => handleVariantChange(item.ID, +e.target.value, 'price')}
                                                />
                                                <TextField
                                                    label="quantity"
                                                    value={item.sellingQuantity}
                                                    size="small"
                                                    type="number" name={`quantity`} id="" className='form-control'
                                                    style={{ width: "30%" }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            zIndex: 0, // Decrease the z-index of the label
                                                        },
                                                    }}
                                                    onChange={(e) => handleVariantChange(item.ID, +e.target.value, 'quantity')}
                                                />
                                                <TextField
                                                    disabled
                                                    label="available"
                                                    value={item.Quantity2}
                                                    size="small"
                                                    type="number" name={`quantity`} id="" className='form-control'
                                                    style={{ width: "30%" }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            zIndex: 0, // Decrease the z-index of the label
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                    <div className=" my-4 border-gray-rounded p-3">
                        <label htmlFor="option1" className='font-semibold'>Customer Details</label>
                        <div className="d-flex justify-content-between align-items-center flex-wrap col-12 ">
                            <div className="" style={{ width: "22%" }}>
                                <label htmlFor="contact" className='form-label'>Contact<span className="required">*</span></label>
                                <input value={orderData.customerContact} type="number" name="contact" id="contact" placeholder='Please enter contact number' className='border-gray-400 form-control' onChange={(e) => setOrderData((prevData) => ({ ...prevData, customerContact: +e.target.value }))} />
                            </div>
                            <div className="" style={{ width: "22%" }}>
                                <label htmlFor="customerName" className='form-label'>Name<span className="required">*</span></label>
                                <input value={orderData.customerName} type="text" name="customerName" id="customerName" placeholder='Please enter name' className='border-gray-400 form-control' onChange={(e) => setOrderData((prevData) => ({ ...prevData, customerName: e.target.value }))} />
                            </div>

                            <div className="" style={{ width: "22%" }}>
                                <label htmlFor="Email" className='form-label'>Email<span className="required">*</span></label>
                                <input value={orderData.customerEmail} type="email" name="Email" id="Email" placeholder='Please enter email' className='border-gray-400 form-control' onChange={(e) => setOrderData((prevData) => ({ ...prevData, customerEmail: e.target.value }))} />
                            </div>
                            <div className="" style={{ width: "22%" }}>
                                <label htmlFor="title" className='form-label font-semibold'>Lead from<span className="required">*</span></label>
                                <Select
                                    value={{ value: orderData.lead, label: orderData.lead }}
                                    onChange={(selectedOption) => setOrderData((prevData) => ({ ...prevData, lead: selectedOption.value }))}
                                    options={['WhatsApp', 'Facebook', 'Instagram']?.map((item) => ({ value: item, label: item }))}

                                />
                            </div>
                        </div>
                        <div className="mt-3 d-flex justify-content-between align-items-center flex-wrap col-12 ">
                            <div className="" style={{ width: "50%" }}>
                                <label htmlFor="customerAddress" className='form-label'>Address<span className="required">*</span></label>
                                <textarea rows={5} value={orderData.customerAddress} name="customerAddress" id="customerAddress" placeholder='Please enter name' className='border-gray-400 form-control' onChange={(e) => setOrderData((prevData) => ({ ...prevData, customerAddress: e.target.value }))} />
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div className='d-flex justify-content-end align-items-center px-3 py-3 bg-white rounded mb-3 my-3'>
                <Button variant="danger light" className='btn-sm me-3' onClick={() => navigate("/order-management")}>Cancel</Button>
                <Button variant="primary " className='btn-sm ' onClick={handleSubmitOrder}>{orderId ? 'Update Order' : 'Create Order'}</Button>
            </div>

        </>
    )
}

export default CreateOrder;







