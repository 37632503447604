import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import CourseSampleFile from '../../../download_files/course_sample_file.docx'
// import CustomClearIndicator from './MultiSelectBatches';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import Select from 'react-select';

let initialFormData = {
    customerContact: '',
    customerName: '',
    customerEmail: '',
    customerAddress: '',
}


const CustomerModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);    //new course
    const [customerId, setCustomerId] = useState(null);
    const [examTypesArray, setExamTypesArray] = useState([]);
    const [formData, setFormData] = useState(initialFormData);
    //check for existing items       
    const [itemAlreadyExist, setItemAlreadyExists] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');//for updating 




    useImperativeHandle(ref, () => ({
        openModal(customerId) {
            if (customerId) {
                setCustomerId(customerId);
                axios.get(BACKEND_SERVER_URL + '/getCustomerData/' + customerId).then((response) => {
                    console.log(response.data.customerData)
                    let customerData = response.data.customerData[0];
                    let formatedData = {                        
                        customerName: customerData.name,
                        customerEmail: customerData.email,
                        customerContact: customerData.contact_number,
                        customerAddress: customerData.address
                    }
                    setFormData(formatedData);
                })
            }
            setModalBox(true);


        }
    }));



    console.log(formData)
    const handleSubmitCustomer = () => {
        axios.put(BACKEND_SERVER_URL + `/updateCustomerData/${customerId}`, formData).then((response) => {
            if (response.data.statusCode == 200) {
                setFormData(initialFormData)
                toast.success(response.data.message)
                props.setCourseLoading(!props.loadCourses)
                setModalBox(false);
                setCustomerId(null)
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log(error)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setFormData(initialFormData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            <Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>Customer Details</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>

                    <div className=" my-4 border-gray-rounded p-3">
                        <label htmlFor="option1" className='font-semibold'>Customer Details</label>
                        <div className="d-flex justify-content-between align-items-center flex-wrap col-12 ">
                            <div className="" style={{ width: "22%" }}>
                                <label htmlFor="contact" className='form-label'>Contact<span className="required">*</span></label>
                                <input value={formData?.customerContact} type="number" name="contact" id="contact" placeholder='Please enter contact number' className='border-gray-400 form-control' onChange={(e) => setFormData((prevData) => ({ ...prevData, customerContact: +e.target.value }))} />
                            </div>
                            <div className="" style={{ width: "22%" }}>
                                <label htmlFor="customerName" className='form-label'>Name<span className="required">*</span></label>
                                <input value={formData?.customerName} type="text" name="customerName" id="customerName" placeholder='Please enter name' className='border-gray-400 form-control' onChange={(e) => setFormData((prevData) => ({ ...prevData, customerName: e.target.value }))} />
                            </div>

                            <div className="" style={{ width: "22%" }}>
                                <label htmlFor="Email" className='form-label'>Email<span className="required">*</span></label>
                                <input value={formData?.customerEmail} type="email" name="Email" id="Email" placeholder='Please enter email' className='border-gray-400 form-control' onChange={(e) => setFormData((prevData) => ({ ...prevData, customerEmail: e.target.value }))} />
                            </div>
                           
                        </div>
                        <div className="mt-3 d-flex justify-content-between align-items-center flex-wrap col-12 ">
                            <div className="" style={{ width: "50%" }}>
                                <label htmlFor="customerAddress" className='form-label'>Address<span className="required">*</span></label>
                                <textarea rows={5} value={formData?.customerAddress} name="customerAddress" id="customerAddress" placeholder='Please enter name' className='border-gray-400 form-control' onChange={(e) => setFormData((prevData) => ({ ...prevData, customerAddress: e.target.value }))} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitCustomer}>Update Customer </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
})
export default CustomerModal;