import React, { Fragment, useEffect, useRef, useState } from "react";
import { BACKEND_SERVER_URL } from "../../../http/http-config";


import axios from "axios";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import swal from 'sweetalert';
import { Badge, Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, showTableResponse } from "../common-helpers/commonServices";
import SearchBox from "../Common-Components/SearchBox";

import '../common-css/Table.css'
import { CiEdit } from "react-icons/ci";
import ShortStockModal from "./ShortStockModal";


const ShortStock = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [checked, setChecked] = useState(courses);
    const [unchecked, setUnChecked] = useState(true);
    const [loadCourses, setCourseLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState(courses);
    const [products, setProducts] = useState(courses);
    const [records, setRecords] = useState(courses);
    const [selectedProduct, setSelectedProduct] = useState({ ID: 0, Title: 'All Products' });



    const [currentPage, setCurrentPage] = useState(1);


    const [count, setCount] = useState(0);

    // search functionality
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const filteredCourses = courses?.filter(
            item => item?.courseTitle?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setSelectedCourses(filteredCourses)
    }, [searchTerm])


    const pageSize = 40;
    const npage = Math.ceil(count / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1);



    useEffect(() => {
        async function fetchData() {
            try {
                let response1 = await axios.get(BACKEND_SERVER_URL + '/getShortVariantsCount');
                let count = response1.data.count;
                setCount(count);
            } catch (error) {
                //toast.error(error.response.data.message)
                console.log(error)
            }
        }
        fetchData();
    }, [loadCourses])

    useEffect(() => {
        async function fetchData() {
            try {
                let response = await axios.get(BACKEND_SERVER_URL + `/getProductWiseVariants/` + selectedProduct.ID);
                setRecords(response.data.variants)
                setCount(response.data.variants?.length || 0)
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                console.log(error)
            }
        }
        if (selectedProduct.ID) {
            fetchData();
            setIsLoading(true)
        } else {
            setCourseLoading(!loadCourses)
        }
    }, [selectedProduct.ID])

    useEffect(() => {
        setIsLoading(true)
        async function fetchData() {
            try {
                let response = await axios.get(BACKEND_SERVER_URL + `/getShortVariantsWithPagination/${currentPage}/${pageSize}`);
                setRecords(response.data.variants)
                setIsLoading(false)

            } catch (error) {
                setIsLoading(false)
                //toast.error(error.response.data.message)
                console.log(error)
            }
        }
        fetchData();
    }, [currentPage, loadCourses])

    useEffect(() => {
        async function fetchData() {
            try {
                let response2 = await axios.get(BACKEND_SERVER_URL + `/getAllProductsForDropDown`);
                response2.data.products.unshift({ ID: 0, Title: 'All Products' })
                setProducts(response2.data.products)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [])

    return (
        <Fragment>
            <div className="col-xl-12">
                <div className=" page-title p-2 mb-2">
                    <div className=' col-8 d-flex justify-content-start'>
                        <div className="col-6">
                            <div className="basic-dropdown">
                                <Select
                                    value={{ value: selectedProduct, label: selectedProduct.Title }}
                                    onChange={(selectedOption) => setSelectedProduct(selectedOption.value)}
                                    options={products.map((product) => ({ value: product, label: product.Title }))}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                            backgroundColor: 'transparent', // Adjust as needed
                                            borderColor: '#17a2b8', // Adjust as needed

                                        }),
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    <div className=" col-4 input-group search-area mb-md-0 mb-3 w-25 " >
                        {/* <input type="text" className="form-control" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search here..." />
                        <span className="input-group-text"><Link to={"#"}>
                            <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z" fill="#01A3FF" />
                            </svg>
                        </Link>
                        </span> */}
                        <SearchBox /* setRecords={setRecords} dataArray={records} searchingProperty={'Title'} */ />
                    </div>
                </div>
                {/* <ProdutsDetailsTabsAtTop moduleType='course' setChecked={setSelectedCourses} totalItems={courses} totalActiveItems={totalActiveCourses} totalInactiveItems={totalInactiveCourses} totalArchivedItems={totalArchivedCourses} /> */}
            </div>
            <div className="dataTables_wrapper no-footer">
                <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div>
                        {/* <Button disabled variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/deleteVariants', 'Variants', setCourseLoading, loadCourses)}>Delete</Button>
                        <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateVariants', 'Variants', setCourseLoading, loadCourses)}>Active</Button>
                        <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inactivateVariants', 'Variants', setCourseLoading, loadCourses)}>Inactive</Button> */}
                    </div>
                    <div className='dataTables_info text-dark'>
                        Records: {count}
                    </div>
                    {count > 1 && <div className="">
                        <div className=""
                            id="example-student_wrapper">
                            <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                        </div>
                    </div>
                    }
                </div>
            </div>

            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                <div className="table-responsive full-data" style={{ paddingBottom: "1px"/* , transform: 'rotateX(180deg)', transform: 'rotateX(180deg)' */ }}>
                    <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                        <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0"/*  style={{ transform: 'rotateX(180deg)' }} */ id="example-student">
                            <thead style={{ backgroundColor: "#cccccc" }} className="custom-table-header">
                                <tr className='text-center  border border-left '>
                                    <th className=' custom-table-header  p-2 '>SN</th>
                                    <th className='p-2 text-start  custom-table-header '>PIC</th>
                                    <th className=' text-start custom-table-header  p-2' >PRODUCT NAME</th>
                                    <th className='p-2 text-start  custom-table-header '>VARIANT</th>
                                    <th className='p-2 custom-table-header '>QUANTITY</th>
                                </tr>
                            </thead>
                            <tbody>
                                {records.length && !isLoading > 0 ? records?.map((item, ind) => (
                                    <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                        <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0"> {item.ID}</h6></td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc", width: "50px" }}>
                                            <div className="">
                                                <img src={item?.Image2} alt="" className="avatar avatar-sm m-auto" />
                                            </div>
                                        </td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="d-flex justify-content-between">
                                                <h6 className='mb-0 d-flex justify-content-between' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                    <span className=""  > {item.Title}{' '}</span>
                                                </h6>
                                            </div>
                                        </td>
                                        <td className='p-1 text-start active-col-width' style={{ border: "1px solid #cccccc", width: '80px' }}><h6 className="mb-0 ">{item.Option1_Value + '-' + item.Option2_Value}</h6></td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="d-flex justify-content-center">
                                                <h6 className='mb-0 d-flex justify-content-center' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                    <span className="text-center"  > {item.Quantity2}{' '}</span>
                                                    <CiEdit cursor='pointer' title='Edit Quantity' size={18} className='ms-5' onClick={() => childRef.current.openModal(item.ID)} />
                                                </h6>
                                            </div>
                                        </td>
                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                {isLoading ? <Spinner animation="grow" variant="info" />
                                                    :
                                                    <h3>No Data Found</h3>
                                                }
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ShortStockModal ref={childRef} loadCourses={loadCourses} setCourseLoading={setCourseLoading} />

        </Fragment>
    );
};

export default ShortStock;







