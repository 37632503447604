import { FaArrowRight } from "react-icons/fa";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

export const MenuList = [
    //Dashboard
    {
        menuId: 1,
        shortName: 'Dashboard',
        title: 'Dashboard',
        /*  classsChange: 'mm-collapse', */
        iconStyle: <i className="material-symbols-outlined">home</i>,
        to: 'dashboard',
    },
    // {
    //     title: 'Product Management',
    //     /*  classsChange: 'menu-title', */
    //     iconStyle: <i className="material-symbols-outlined">category</i>,
    //     to: 'product-management',
    // },
    {
        menuId: 2,
        shortName: 'Products',
        title: 'Product Management',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-symbols-outlined">category</i>,
        content: [
            {
                title: 'Add Product',
                to: 'create-product',
                // iconStyle: <FaArrowRight />
            },
            {
                title: 'Product Details',
                to: 'product-management',
                // iconStyle: <FaArrowRight />
            },

        ],
    },
    {
        menuId: 3,
        shortName: 'Orders',
        title: 'Order Management',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-symbols-outlined">shopping_cart</i>,
        content: [
            {
                title: 'Add Order',
                to: 'create-order',
                // iconStyle: <FaArrowRight />
            },
            {
                title: 'Order Details',
                to: 'order-management',
                // iconStyle: <FaArrowRight />
            },

        ],
    },
    {
        menuId: 4,
        shortName: 'Customers',
        title: 'Customer Details',
        /*  classsChange: 'menu-title', */
        iconStyle: <i className="material-symbols-outlined">contacts</i>,
        to: 'customer-details',
    },
    {
        menuId: 5,
        shortName: 'Staffs',
        title: 'Staff Management',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-symbols-outlined">group</i>,
        content: [
            {
                title: 'Add Staff',
                to: 'create-staff',
                // iconStyle: <FaArrowRight />
            },
            {
                title: 'Staff Details',
                to: 'staff-management',
                // iconStyle: <FaArrowRight />
            },

        ],
    },
    {
        menuId: 6,
        shortName: 'Stocks',
        title: 'Stock Management',
        /*  classsChange: 'menu-title', */
        iconStyle: <i className="material-symbols-outlined">inventory</i>,
        to: 'stock-management',
    },
    {
        menuId: 7,
        shortName: 'Short_Stock',
        title: 'Short Stock',
        /*  classsChange: 'menu-title', */
        iconStyle: <i className="material-symbols-outlined">warning</i>,
        to: 'short-stock',
    },
    {
        menuId: 8,
        shortName: 'Report',
        title: 'Report Management',
        /*  classsChange: 'menu-title', */
        iconStyle: <i className="material-symbols-outlined">summarize</i>,
        to: 'report-management',
    },
    {
        menuId: 9,
        shortName: 'My_Profile',
        title: 'My Profile',
        iconStyle: <i className="material-icons">person_outline</i>,
        to: 'my-account',
    },
    // {
    //     title: 'Batch Details',
    //     iconStyle: <i className="material-icons">collections</i>,
    //     to: 'batch-management',
    // },
    // {
    //     title: 'Test Series Module',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">quiz</i>,
    //     content: [
    //         {
    //             title: 'Test Series',
    //             to: 'test-series',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Test Reports',
    //             to: 'test-reports',
    //             iconStyle: <FaArrowRight />
    //         },

    //     ],
    // },
    // {
    //     title: 'Books',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">book</i>,
    //     content: [
    //         {
    //             title: 'Books',
    //             to: 'books',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Subjects',
    //             to: 'books-subjects',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Lessons',
    //             to: 'books-lessons',
    //             iconStyle: <FaArrowRight />
    //         },
    //         // {
    //         //     title: 'Practice Tests',
    //         //     to: 'practice-tests',
    //         //     iconStyle: <FaArrowRight />
    //         // },

    //     ],
    // },
    // {
    //     title: 'Books',
    //     iconStyle: <i className="material-icons">book</i>,
    //     to: 'books',
    // },
    // {
    //     title: 'Pending Mains',
    //     to: 'pending-mains',
    //     iconStyle: <i className="material-icons">help_outline</i>
    // },
    // {
    //     title: 'Copy Questions',
    //     to: 'copy-questions',
    //     iconStyle: <i className="material-icons">insert_drive_file</i>
    // },
    // {
    //     title: 'Quiz Details',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">question_answer</i>,
    //     content: [
    //         {
    //             title: 'Subject Details',
    //             to: 'quiz-subjects',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Section Details',
    //             to: 'quiz-sections',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Question Bank',
    //             to: 'quiz-questions',
    //             iconStyle: <FaArrowRight />
    //         },
    //     ],
    // },
    // {
    //     title: 'Students',
    //     to: 'student-management',
    //     iconStyle: <i className="material-icons">people</i>
    // },
    // {
    //     title: 'Current Affairs',
    //     to: 'current-affairs',
    //     iconStyle: <i className="material-icons">article</i> // Use 'article' icon for news or articles
    // },
    // {
    //     title: 'Blog Details',
    //     to: 'blogs',
    //     iconStyle: <i className="material-icons">note</i> // Use 'description' icon for blog details
    // },
    // {
    //     title: 'Order Details',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">shopping_cart</i>,
    //     content: [
    //         {
    //             title: 'Course Orders',
    //             to: 'orders/course-orders',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Test Series Orders',
    //             to: 'orders/testseries-orders',
    //             iconStyle: <FaArrowRight />
    //         },

    //     ],
    // },


    // {
    //     title: 'Order Details',
    //     to: 'orders-management',
    //     iconStyle: <i className="material-icons">shopping_cart</i>
    // },

    // {
    //     title: 'Teacher Support',
    //     to: 'teacher-support',
    //     iconStyle: <i className="material-icons">live_help</i>
    // },
    // {
    //     title: 'Website Query',
    //     to: 'website-query',
    //     iconStyle: <i className="material-icons">search</i>
    // },
    // {
    //     title: 'Operation Setting',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">settings</i>,
    //     content: [
    //         {
    //             title: 'Academic Year',
    //             to: 'academic-year',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Exam Body',
    //             to: 'exam-body',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Module Type',
    //             to: 'module-type',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'News Category',
    //             to: 'news-category',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Blog Category',
    //             to: 'blog-category',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'News Tags',
    //             to: 'news-tags',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Faculty',
    //             to: 'faculty',
    //             iconStyle: <FaArrowRight />
    //         },
    //         {
    //             title: 'Testimonials',
    //             to: 'testimonials',
    //             iconStyle: <FaArrowRight />
    //         },

    //     ],
    // },




    // {
    //     title: 'Staff Details ',
    //     iconStyle: <i className="material-icons">group</i>,
    //     to: 'staff-details',
    // },




























    // {
    //     title: 'Student',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">school</i>,
    //     content: [
    //         {
    //             title: 'Student',
    //             to: 'student',
    //         },
    //         {
    //             title: 'Student Detail',
    //             to: 'student-detail',
    //         },
    //         {
    //             title: 'Add New Student',
    //             to: 'add-student',
    //         },

    //     ],
    // },
    // {
    //     title: 'Teacher',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">person</i>,
    //     content: [
    //         {
    //             title: 'Teacher',
    //             to: 'teacher',
    //         },
    //         {
    //             title: 'Teacher Detail',
    //             to: 'teacher-detail',
    //         },
    //         {
    //             title: 'Add New Teacher',
    //             to: 'add-teacher',
    //         },
    //     ],
    // },
    // {
    //     title: 'Food',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-symbols-outlined">restaurant_menu</i>,
    //     content: [
    //         {
    //             title: 'Food menu',
    //             to: 'food',
    //         },
    //         {
    //             title: 'Food Detail',
    //             to: 'food-details',
    //         },
    //     ],
    // },
    // {
    //     title: 'File Manager',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons">folder</i>,
    //     content: [
    //         {
    //             title: 'File Manager',
    //             to: 'file-manager',
    //         },
    //         {
    //             title: 'User',
    //             to: 'user',
    //         },
    //         {
    //             title: 'Calendar',
    //             to: 'calendar',
    //         },
    //         {
    //             title: 'Chat',
    //             to: 'chat',
    //         },
    //         {
    //             title: 'Activity',
    //             to: 'activity',
    //         },
    //     ],
    // },
    //Apps
    // {
    //     title: 'Apps',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons">app_registration </i>,
    //     content: [
    //         {
    //             title: 'Profile',
    //             to: 'app-profile'
    //         },
    //         {
    //             title: 'Edit Profile',
    //             to: 'edit-profile'
    //         },
    //         {
    //             title: 'Post Details',
    //             to: 'post-details'
    //         },
    //         {
    //             title: 'Email',
    //             //to: './',
    //             hasMenu: true,
    //             content: [
    //                 {
    //                     title: 'Compose',
    //                     to: 'email-compose',
    //                 },
    //                 {
    //                     title: 'Index',
    //                     to: 'email-inbox',
    //                 },
    //                 {
    //                     title: 'Read',
    //                     to: 'email-read',
    //                 }
    //             ],
    //         },
    //         {
    //             title: 'Calendar',
    //             to: 'app-calender'
    //         },
    //         {
    //             title: 'Shop',
    //             //to: './',
    //             hasMenu: true,
    //             content: [
    //                 {
    //                     title: 'Product Grid',
    //                     to: 'ecom-product-grid',
    //                 },
    //                 {
    //                     title: 'Product List',
    //                     to: 'ecom-product-list',
    //                 },
    //                 {
    //                     title: 'Product Details',
    //                     to: 'ecom-product-detail',
    //                 },
    //                 {
    //                     title: 'Order',
    //                     to: 'ecom-product-order',
    //                 },
    //                 {
    //                     title: 'Checkout',
    //                     to: 'ecom-checkout',
    //                 },
    //                 {
    //                     title: 'Invoice',
    //                     to: 'ecom-invoice',
    //                 },
    //                 {
    //                     title: 'Customers',
    //                     to: 'ecom-customers',
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // //Charts
    // {
    //     title: 'Charts',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons">assessment</i>,
    //     content: [

    //         {
    //             title: 'RechartJs',
    //             to: 'chart-rechart',
    //         },
    //         {
    //             title: 'Chartjs',
    //             to: 'chart-chartjs',
    //         },
    //         {
    //             title: 'Sparkline',
    //             to: 'chart-sparkline',
    //         },
    //         {
    //             title: 'Apexchart',
    //             to: 'chart-apexchart',
    //         },
    //     ]
    // },
    // //Boosttrap
    // {
    //     title: 'Bootstrap',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons">favorite</i>,
    //     content: [
    //         {
    //             title: 'Accordion',
    //             to: 'ui-accordion',
    //         },
    //         {
    //             title: 'Alert',
    //             to: 'ui-alert',
    //         },
    //         {
    //             title: 'Badge',
    //             to: 'ui-badge',
    //         },
    //         {
    //             title: 'Button',
    //             to: 'ui-button',
    //         },
    //         {
    //             title: 'Modal',
    //             to: 'ui-modal',
    //         },
    //         {
    //             title: 'Button Group',
    //             to: 'ui-button-group',
    //         },
    //         {
    //             title: 'List Group',
    //             to: 'ui-list-group',
    //         },
    //         {
    //             title: 'Cards',
    //             to: 'ui-card',
    //         },
    //         {
    //             title: 'Carousel',
    //             to: 'ui-carousel',
    //         },
    //         {
    //             title: 'Dropdown',
    //             to: 'ui-dropdown',
    //         },
    //         {
    //             title: 'Popover',
    //             to: 'ui-popover',
    //         },
    //         {
    //             title: 'Progressbar',
    //             to: 'ui-progressbar',
    //         },
    //         {
    //             title: 'Tab',
    //             to: 'ui-tab',
    //         },
    //         {
    //             title: 'Typography',
    //             to: 'ui-typography',
    //         },
    //         {
    //             title: 'Pagination',
    //             to: 'ui-pagination',
    //         },
    //         {
    //             title: 'Grid',
    //             to: 'ui-grid',
    //         },
    //     ]
    // },
    // //plugins
    // {
    //     title: 'Plugins',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons">extension </i>,
    //     content: [
    //         {
    //             title: 'Select 2',
    //             to: 'uc-select2',
    //         },
    //         // {
    //         //     title:'Noui Slider',
    //         //     to: 'uc-noui-slider',
    //         // },
    //         {
    //             title: 'Sweet Alert',
    //             to: 'uc-sweetalert',
    //         },
    //         {
    //             title: 'Toastr',
    //             to: 'uc-toastr',
    //         },
    //         // {
    //         //     title:'Jqv Map',
    //         //     to: 'map-jqvmap',
    //         // },
    //         {
    //             title: 'Light Gallery',
    //             to: 'uc-lightgallery',
    //         },
    //     ]
    // },
    // //Widget
    // {
    //     title: 'Widget',
    //     iconStyle: <i className="material-icons">widgets</i>,
    //     to: 'widget',
    // },
    // //Forms
    // {
    //     title: 'Forms',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons">insert_drive_file</i>,
    //     content: [
    //         {
    //             title: 'Form Elements',
    //             to: 'form-element',
    //         },
    //         {
    //             title: 'Wizard',
    //             to: 'form-wizard',
    //         },
    //         {
    //             title: 'CkEditor',
    //             to: 'form-ckeditor',
    //         },
    //         {
    //             title: 'Pickers',
    //             to: 'form-pickers',
    //         },
    //         {
    //             title: 'Form Validate',
    //             to: 'form-validation',
    //         },

    //     ]
    // },
    // //Table
    // {
    //     title: 'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons">table_chart</i>,
    //     content: [
    //         {
    //             title: 'Table Filtering',
    //             to: 'table-filtering',
    //         },
    //         {
    //             title: 'Table Sorting',
    //             to: 'table-sorting',
    //         },
    //         {
    //             title: 'Bootstrap',
    //             to: 'table-bootstrap-basic',
    //         },


    //     ]
    // },
    // //Pages
    // {
    //     title: 'Pages',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="merial-icons">article</i>,
    //     content: [
    //         {
    //             title: 'Error',
    //             hasMenu: true,
    //             content: [
    //                 {
    //                     title: 'Error 400',
    //                     to: 'page-error-400',
    //                 },
    //                 {
    //                     title: 'Error 403',
    //                     to: 'page-error-403',
    //                 },
    //                 {
    //                     title: 'Error 404',
    //                     to: 'page-error-404',
    //                 },
    //                 {
    //                     title: 'Error 500',
    //                     to: 'page-error-500',
    //                 },
    //                 {
    //                     title: 'Error 503',
    //                     to: 'page-error-503',
    //                 },
    //             ],
    //         },
    //         {
    //             title: 'Lock Screen',
    //             to: 'page-lock-screen',
    //         },

    //     ]
    // },

]