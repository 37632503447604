import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MenuList } from '../../layouts/nav/Menu';

import Select from "react-select";
import { BACKEND_SERVER_URL } from "../../../http/http-config";
import { getExamTypes } from "../Course/CourseHelper";
/* const batchData = [
    { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
    { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630", isFixed: true },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
]; */

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {
    const {
        children = <CustomClearText />,
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles("clearIndicator", props)}
        >
            <div style={{ padding: "0px 5px" }}>{children}</div>
        </div>
    );
};

const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: "pointer",
    color: state.isFocused ? "blue" : "black",
});

export default function CustomClearIndicator({ setExamBodiesIDsToBeReturn, previouslyMappedExamIds }) {
    const [selectedBatches, setSelectedBatches] = useState([]);

    const batchesToShow = MenuList.filter(obj=>obj.menuId!=5)?.map((exam) => ({
        id: exam.menuId,
        value: exam.shortName,
        label: exam?.shortName
    }));


    useEffect(() => {
        let selectedIds = selectedBatches.map(obj => obj.id);
        setExamBodiesIDsToBeReturn(selectedIds);
    }, [selectedBatches])
    useEffect(() => {
        if (previouslyMappedExamIds.length > 0) {
            let previousTabs = MenuList?.filter((obj) => previouslyMappedExamIds.includes(obj.menuId))

            previousTabs = previousTabs?.map((exam) => ({
                id: exam.menuId,
                value: exam.shortName,
                label: exam.shortName
            }));
            console.log(previousTabs);
            // setDefaultValue(previousTabs);
            setSelectedBatches(previousTabs);
        } else {
            setSelectedBatches([]);
            // setDefaultValue([]);
        }

    }, [previouslyMappedExamIds, MenuList])
    const handleChange = (selectedOptions) => {
        setSelectedBatches(selectedOptions);
    };
    return (
        <>
            {batchesToShow && (
                <>
                    <Select
                        closeMenuOnSelect={false}
                        components={{ ClearIndicator }}
                        styles={{ clearIndicator: ClearIndicatorStyles }}
                        // defaultValue={selectedBatches}
                        isMulti
                        value={selectedBatches}
                        options={batchesToShow}
                        onChange={handleChange}
                        isSearchable={true}
                    />

                </>
            )}
        </>
    );

}
