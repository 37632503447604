import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { checkExistingItem } from '../common-helpers/commonServices';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@ckeditor/ckeditor5-build-classic';

import FileInput from '../Common-Components/FileInput';
import CKEditorInput from '../Common-Components/CKEditorInput';



const TeacherReplyModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
   

    console.log(userDetails)
    let initialData = {
        sn: 0,
        firstName: '',
        lastName: '',
        sort: 0,
        teacherReply: '',
        file: '',
        ctype: 'question',
        userCode:""        
    }

    const [replyData, setReplyData] = useState(initialData);
  

    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setReplyData({
                    sn: item.sn, teacherReply: item.teacherReply, file: item.teacherFile || '', firstName: item.firstName, lastName: item.lastName, ctype: item.ctype || 'question', userCode: item.userCode, facultyName: userDetails?.FirstName
                })
            }
            setModalBox(true);
        }
    }));

    const handleSaveCourseType = (value) => {
        // if (replyData.teacherReply.trim().length === 0) {
        //     toast.error("Input Field can not be Blank")
        // }
        console.log(replyData)

        axios.post(BACKEND_SERVER_URL + '/saveTeacherReply', replyData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setTeacherCommentsLoading(!props.loadTeacherComments);
                setReplyData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setReplyData(initialData); // Reset form data when modal is closed 
        }
    }, [modalBox]);

    const handleChange = (name, value) => {
        setReplyData((preData) => (
            { ...preData, [name]: value }
        ));
    };


    //mage upload
    function uploadAdapter(loader) {
        console.log(loader)
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        console.log('Uploading file:', file);
                        body.append("uploadedFile", file);
                        console.log('body', body)
                        axios.post(BACKEND_SERVER_URL + '/uploadFile', body).then(res => {
                            resolve({ default: res.data.imagePath })
                        }).catch(error => {
                            reject(error)
                        })
                    })
                })
            }
        }
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            console.log('Creating upload adapter for loader:', loader);
            return uploadAdapter(loader)
        }
    }
    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} backdrop="static" dialogClassName="custom-modal-width"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> Teacher Reply</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary">Teacher Reply</label>
                            <div className="card-body custom-ekeditor ms-0 ps-0 pt-0">
                                <CKEditor
                                    config={{
                                        extraPlugins: [uploadPlugin],
                                    }}
                                    editor={Editor}
                                    // data="<p>Hello from CKEditor 5!</p>"
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                    }}
                                    data={replyData.teacherReply ?? ''}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        console.log('data', data)
                                        handleChange('teacherReply', data)

                                    }}
                                    onBlur={(event, editor) => {
                                    }}
                                    onFocus={(event, editor) => {
                                        //console.log( 'Focus.', editor );
                                    }}

                                />
                            </div>

                        </div>
                        <div className="">
                            <FileInput isMandatory={false} handleChangeCallBack={handleChange} labelName="Upload Answer" inputType="text" uploadedFileURL={replyData?.file} inputName='file' accept=".pdf,.png,.jpg" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveCourseType}>Reply</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default TeacherReplyModal;