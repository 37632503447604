import React, { useEffect, useState } from 'react';
import { SVGICON } from '../Dashboard/Content';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Alert } from 'react-bootstrap';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const userDetails = JSON.parse(tokenDetailsString)




    const [data, setData] = useState({ totalStudents: 0, totalFaculties: 0, totalCourses: 0, totalTestSeries: 0, totalBatches: 0, totalBlogs: 0, totalNews: 0, totalOrders: 0 })



    const cardBlog1 = [
        { title: 'Students', svg: SVGICON.user, number: data.totalStudents, change: 'std-data' },
        { title: 'Faculty', svg: SVGICON.user2, number: data.totalFaculties, change: 'food-data' },
        { title: 'Courses', svg: SVGICON.course, number: data.totalCourses, change: 'event-data' },
        { title: 'Batches', svg: SVGICON.batch, number: data.totalBatches, change: 'teach-data' },

        // { title: 'Foods', svg: SVGICON.food, number: '32K', change: 'food-data bg-dark' },
    ];
    const cardBlog2 = [
        { title: 'Test Series', svg: SVGICON.testSeries, number: data.totalTestSeries, change: 'std-data' },
        { title: 'News', svg: SVGICON.news, number: data.totalNews, change: 'food-data' },
        { title: 'Blogs', svg: SVGICON.blogs, number: data.totalBlogs, change: 'event-data' },
        { title: 'Orders', svg: SVGICON.orders, number: data.totalOrders, change: 'teach-data' },
        // { title: 'Foods', svg: SVGICON.food, number: '32K', change: 'food-data bg-dark' },
    ];

    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/dashboard').then((res) => {
            console.log(res)
            setData(res.data.dashboardData)
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.meassage)
        })
    }, [])

    return (
        <>
            <div className="row">
                <div className="col-12 vh-100">
                    <div className="card">
                        <div className="card-body">
                            <h3>
                                Hey <span className='text-success'>{userDetails.name}</span>!
                            </h3>
                            <br />
                            <h5 className='text-primary' style={{ color: "black" }}>

                                Welcome to <span className='text-danger'> Cartroof</span>  Its your dashboard for your latest lead operation.
                            </h5>
                        </div>
                    </div>
                </div>

                {/* <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body pb-xl-4 pb-sm-3 pb-0">
                            <div className="row">
                                {cardBlog1.map((item, ind) => (
                                    <div className="col-xl-3 col-6" key={ind}>
                                        <div className="content-box">
                                            <div className={`icon-box icon-box-xl ${item.change}`}>
                                                {item.svg}
                                            </div>
                                            <div className="chart-num">
                                                <p>{item.title}</p>
                                                <h2 className="font-w700 mb-0">{item.number}</h2>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body pb-xl-4 pb-sm-3 pb-0">
                            <div className="row">
                                {cardBlog2.map((item, ind) => (
                                    <div className="col-xl-3 col-6" key={ind}>
                                        <div className="content-box">
                                            <div className={`icon-box icon-box-xl ${item.change}`}>
                                                {item.svg}
                                            </div>
                                            <div className="chart-num">
                                                <p>{item.title}</p>
                                                <h2 className="font-w700 mb-0">{item.number}</h2>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </>
    );
}

export default AdminDashboard;


