import axios from 'axios';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import { toast } from 'react-toastify';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';


export function signupAction(email, password, navigate) {

    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    //history,
                );
                dispatch(confirmedSignupAction(response.data));
                navigate('/dashboard');
                //history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function Logout(navigate) {
    localStorage.removeItem('userDetails');
    navigate('/login');
    //history.push('/login');

    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, userRole, navigate) {  
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                console.log(response.data)
                if (response) {
                    console.log(response.data.userData)
                    saveTokenInLocalStorage(response.data.userData);
                    runLogoutTimer(
                        dispatch,
                        response.data.userData.expiresIn *1000,
                        navigate,
                    );
                    dispatch(loginConfirmedAction(response.data.userData));
                    axios.defaults.headers.common['userType'] = response.data.userData.type;
                  /*          axios.defaults.headers.common['adminId'] = response.data?.adminId;
                          axios.defaults.headers.common['Authorization'] = response.data?.idToken; */
                    navigate('/dashboard');
                }
            })
            .catch((error) => {
                console.log(error)
              const errorMessage = formatError(error.response?.data.message);
                toast.error(error.response.data.message);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export const navtoggle = () => {
    return {
        type: 'NAVTOGGLE',
    };
};